import { createSelector } from '@reduxjs/toolkit'
import * as config from '../config/values';
import {
  selectAnnualEmissionsElectricVehiclesScenario,
  selectAnnualEmissionsCombustionVehiclesScenario
} from './vehiclesSelectors.js';
import {
  selectAnnualHPConsumpScenario,
  selectAnnualAppliancesConsumption,
  selectAnnualGasNaturalConsumptionsScenario
} from './consumptionsSelectors.js';
import {
  selectAnnualElectricalGenerationScenario
} from './generationsSelectors.js';

export const selectAnnualAppliancesEmmisions = createSelector(
  selectAnnualAppliancesConsumption, 
  (appliances) => {
    console.log('---appliances---');
    console.log(appliances);
    return appliances * config.emissionFactor.electricity / 1000;
  }
);

export const selectAnnualThermalEmissionsScenario = createSelector(
  selectAnnualGasNaturalConsumptionsScenario,
  (state, scenario) => selectAnnualHPConsumpScenario(state, scenario, 'geotermia'),
  (state, scenario) => selectAnnualHPConsumpScenario(state, scenario, 'aerotermia'),
  (gas, hpGeo, hpAero) => {
    console.log('---thermal---');
    console.log(gas);
    console.log(hpGeo);
    console.log(hpAero);
    return gas / 1000 * config.emissionFactor.gasNatural
      + (hpGeo + hpAero) / 1000 * config.emissionFactor.electricity;
  }
);

export const selectAnnualTransportationEmissionsScenario = createSelector(
  selectAnnualEmissionsElectricVehiclesScenario,
  selectAnnualEmissionsCombustionVehiclesScenario,
  (evEmisisons, combEmissions) => {
    console.log('---transports---');
    console.log(evEmisisons);
    console.log(combEmissions);
    return evEmisisons + combEmissions;
  }
);

export const selectAnnualPVEmissionsScenario = createSelector(
  selectAnnualElectricalGenerationScenario,
  (pvGeneration) => {
    return -pvGeneration / 1000 * config.emissionFactor.electricity;
  }
);

export const selectAnnualThermalEmissions = createSelector(
  state => selectAnnualThermalEmissionsScenario(state, "Base"),
  state => selectAnnualThermalEmissionsScenario(state, "Escenario 1"),
  state => selectAnnualThermalEmissionsScenario(state, "Escenario 2"),
  (base, scenario1, scenario2) => {
    return {
      Base: base,
      'Escenario 1': scenario1,
      'Escenario 2': scenario2 
    };
  }
);

export const selectAnnualTransportationEmissions = createSelector(
  state => selectAnnualTransportationEmissionsScenario(state, "Base"),
  state => selectAnnualTransportationEmissionsScenario(state, "Escenario 1"),
  state => selectAnnualTransportationEmissionsScenario(state, "Escenario 2"),
  (base, scenario1, scenario2) => {
    return {
      Base: base,
      'Escenario 1': scenario1,
      'Escenario 2': scenario2 
    };
  }
);

export const selectAnnualPVEmissions = createSelector(
  state => selectAnnualPVEmissionsScenario(state, "Base"),
  state => selectAnnualPVEmissionsScenario(state, "Escenario 1"),
  state => selectAnnualPVEmissionsScenario(state, "Escenario 2"),
  (base, scenario1, scenario2) => {
    return {
      Base: base,
      'Escenario 1': scenario1,
      'Escenario 2': scenario2 
    };
  }
);
