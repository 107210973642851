import { useState } from 'react'
import { connect, useSelector } from 'react-redux';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

import {
  updateNeighbourhoodArea,
  selectNeighbourhoodArea
} from '../../../features/commonDataSlice';

import CombinationLockInput from './CombinationLockInput';

const ItemNeighbourhoodArea = ({ dispatch }) => {
  const area = useSelector(selectNeighbourhoodArea);
  const [isOpen, setIsOpen] = useState(false)

  const update = (value) => {
    console.log("update in itemArea", value)
    dispatch(updateNeighbourhoodArea(value/100));
  };

  return (
    <ListItem sx={{ flexWrap: 'wrap' }}>
      <ListItemText
        id="switch-list-label-area"
        primary="Superficie del barrio:"
        secondary="edificable"
        sx={{ minWidth: '173px' }}
      />
      <TextField
        variant="standard"
        value={area}
        inputProps={{ style: { textAlign: 'right' }}}
        InputProps={{
          endAdornment: <InputAdornment position="end">km²</InputAdornment>,
        }}
        sx={{ width: 90, flexShrink: 0 }}
        onClick={() => setIsOpen(true)}
      />
      <CombinationLockInput
        num={String(parseInt(area*100, 10)).padStart(3, '0')}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        update={update}
        text="Selecciona la superficie edificable del barrio:"
      />
    </ListItem>
  );
};

export default connect()(ItemNeighbourhoodArea);
