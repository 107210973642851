import { connect, useSelector } from 'react-redux';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Slider from '@mui/material/Slider';

import {
  setEvPercentage,
  selectVehiclesEVPercentage
} from '../../../features/scenariosSlice';

const ItemEVPercentage = ({ scenario, dispatch }) => {
  const percentage = useSelector(state => {
    return selectVehiclesEVPercentage(state, scenario)
  });

  const update = (value) => {
    console.log("update in itemEVPercentage", value)
    dispatch(setEvPercentage({ scenario: scenario, evPercentage: value }));
  };

  return (
    <ListItem sx={{ flexWrap: 'wrap' }} disabled={scenario === "Base"}>
      <ListItemText
        id="switch-list-label-percentage"
        primary="Porcentaje de vehículos eléctricos:"
        sx={{ width: '200px' }}
      />
      <Slider
        value={percentage}
        onChange={(v) => update(v.target.value)}
        min={0}
        max={100}
        valueLabelDisplay="auto"
        valueLabelFormat={(value) => `${value}%`}
      />
    </ListItem>
  );
};

export default connect()(ItemEVPercentage);
