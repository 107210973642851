import { createSelector } from '@reduxjs/toolkit'
import * as config from '../config/values';
import {
  selectMonthlyTotalKms
} from './commonDataSlice';
import {
  selectVehiclesEVPercentage
} from './scenariosSlice'

// CONSUMPTIONS //

// Electric Vehicles

export const selectMonthlyElectricKmsScenario = createSelector(
  selectVehiclesEVPercentage,
  selectMonthlyTotalKms,
  (evPercentage, monthlyKms) => {
    return monthlyKms.map(kms => {
      return kms * evPercentage/100;
    });
  }
);

export const selectMonthlyConsumptionElectricVehiclesScenario = createSelector(
  selectMonthlyElectricKmsScenario,
  (monthlyKms) => {
    return monthlyKms.map(kms => {
      const elecPerKm = config.conversionFactors.electricPerKm;
      return kms * elecPerKm;
    });
  }
);

export const selectConsumptionElectricVehiclesInMonthScenario = createSelector(
  (state, month_index) => month_index,
  selectMonthlyConsumptionElectricVehiclesScenario,
  (month, monthlyConsumption) => {
    return monthlyConsumption[month];
  }
);

export const selectAnnualConsumptionElectricVehiclesScenario = createSelector(
  selectMonthlyConsumptionElectricVehiclesScenario,
  (monthlyConsumption) => {
    return monthlyConsumption.reduce(
      (acc, month_consump) => acc + month_consump, 0
    );
  }
);

export const selectMonthlyConsumptionElectricVehicles = createSelector(
  state => selectMonthlyConsumptionElectricVehiclesScenario(state, 'Base'),
  state => selectMonthlyConsumptionElectricVehiclesScenario(state, 'Escenario 1'),
  state => selectMonthlyConsumptionElectricVehiclesScenario(state, 'Escenario 2'),
  (base, scenario1, scenario2) => {
    return {
      Base: base,
      'Escenario 1': scenario1,
      'Escenario 2': scenario2 
    };
  }
);

export const selectAnnualConsumptionElectricVehicles = createSelector(
  state => selectAnnualConsumptionElectricVehiclesScenario(state, "Base"),
  state => selectAnnualConsumptionElectricVehiclesScenario(state, "Escenario 1"),
  state => selectAnnualConsumptionElectricVehiclesScenario(state, "Escenario 2"),
  (base, scenario1, scenario2) => {
    return {
      Base: base,
      'Escenario 1': scenario1,
      'Escenario 2': scenario2 
    };
  }
);

// Combustion Vehicles

export const selectMonthlyCombustionKmsScenario = createSelector(
  selectVehiclesEVPercentage,
  selectMonthlyTotalKms,
  (evPercentage, monthlyKms) => {
    console.log(evPercentage, monthlyKms);
    return monthlyKms.map(kms => {
      return kms * (1 - evPercentage/100);
    });
  }
);

export const selectMonthlyConsumptionCombustionVehiclesScenario = createSelector(
  selectMonthlyCombustionKmsScenario,
  (monthlyKms) => {
    return monthlyKms.map(kms => {
      const gasPerKm = config.conversionFactors.gasPerKm;
      const ltsGasToEnergy = config.conversionFactors.ltsGasToEnergy;
      return kms * gasPerKm * ltsGasToEnergy;
    });
  }
);

export const selectConsumptionCombustionVehiclesInMonthScenario = createSelector(
  (state, month_index) => month_index,
  selectMonthlyConsumptionCombustionVehiclesScenario,
  (month, monthlyConsumption) => {
    return monthlyConsumption[month];
  }
);

export const selectAnnualConsumptionCombustionVehiclesScenario = createSelector(
  selectMonthlyConsumptionCombustionVehiclesScenario,
  (monthlyConsumption) => {
    return monthlyConsumption.reduce(
      (acc, month_consump) => acc + month_consump, 0
    );
  }
);

export const selectMonthlyConsumptionCombustionVehicles = createSelector(
  state => selectMonthlyConsumptionCombustionVehiclesScenario(state, 'Base'),
  state => selectMonthlyConsumptionCombustionVehiclesScenario(state, 'Escenario 1'),
  state => selectMonthlyConsumptionCombustionVehiclesScenario(state, 'Escenario 2'),
  (base, scenario1, scenario2) => {
    return {
      Base: base,
      'Escenario 1': scenario1,
      'Escenario 2': scenario2 
    };
  }
);

export const selectAnnualConsumptionCombustionVehicles = createSelector(
  state => selectAnnualConsumptionCombustionVehiclesScenario(state, "Base"),
  state => selectAnnualConsumptionCombustionVehiclesScenario(state, "Escenario 1"),
  state => selectAnnualConsumptionCombustionVehiclesScenario(state, "Escenario 2"),
  (base, scenario1, scenario2) => {
    return {
      Base: base,
      'Escenario 1': scenario1,
      'Escenario 2': scenario2 
    };
  }
);

// EMISSIONS //

// Electric Vehicles

export const selectMonthlyEmissionsElectricVehiclesScenario = createSelector(
  selectMonthlyElectricKmsScenario,
  (monthlyKms) => {
    return monthlyKms.map(kms => {
      return kms/100 * config.emissionFactor.ve / 1000;
    });
  }
);

export const selectEmissionsElectricVehiclesInMonthScenario = createSelector(
  (state, month_index) => month_index,
  selectMonthlyEmissionsElectricVehiclesScenario,
  (month, monthlyEmissions) => {
    return monthlyEmissions[month];
  }
);

export const selectAnnualEmissionsElectricVehiclesScenario = createSelector(
  selectMonthlyEmissionsElectricVehiclesScenario,
  (monthlyEmissions) => {
    return monthlyEmissions.reduce(
      (acc, month_emissions) => acc + month_emissions, 0
    );
  }
);

// Combustion Vehicles

export const selectMonthlyEmissionsCombustionVehiclesScenario = createSelector(
  selectMonthlyCombustionKmsScenario,
  (monthlyKms) => {
    return monthlyKms.map(kms => {
      return kms/100 * config.emissionFactor.gasoline / 1000;
    });
  }
);

export const selectEmissionsCombustionVehiclesInMonthScenario = createSelector(
  (state, month_index) => month_index,
  selectMonthlyEmissionsCombustionVehiclesScenario,
  (month, monthlyEmissions) => {
    return monthlyEmissions[month];
  }
);

export const selectAnnualEmissionsCombustionVehiclesScenario = createSelector(
  selectMonthlyEmissionsCombustionVehiclesScenario,
  (monthlyEmissions) => {
    return monthlyEmissions.reduce(
      (acc, month_emissions) => acc + month_emissions, 0
    );
  }
);
