import {
  Pie,
  Sector,
  Legend,
  PieChart,
  ResponsiveContainer
} from 'recharts';

import { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectEnergeticMix } from '../../features/chartSelectors';

const colors = { 'Base': '#931B80', 'Escenario 1': '#2DAAE2', 'Escenario 2': '#DCDC00'};

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  const finalValue = Math.round((value + Number.EPSILON) * 100) / 100;

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={/*fill*/ "#555"}>
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`${(finalValue/1000).toFixed(2)} Mwh`}</text>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#777">
        {`(${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
};

const ChartEnergeticMix = () => {
  const data = useSelector(selectEnergeticMix);
  const [activeIndex, setActiveIndex] = useState(0);

  console.log("ChartEnergeticMix", data);
  const positions = Object.keys(data).length === 2
    ? [["33%", "50%"], ["83%", "50%"]]
    : [["27%", "35%"], ["58.5%", "75%"], ["87%", "35%"]]

  const legendPayload = Object.keys(data).map(scenario => ({
    value: scenario,
    type: 'square',
    id: scenario,
    color: colors[scenario],
  }));

  return (
    <ResponsiveContainer width="99%" height="99%">
      <PieChart>
        {Object.keys(data).map((scenario, index) => (
          <Pie
            activeIndex={activeIndex}
            activeShape={renderActiveShape}
            name={scenario}
            key={scenario}
            data={data[scenario]}
            dataKey="value"
            innerRadius={70}
            outerRadius={100}
            labelLine={false}
            cx={positions[index][0]}
            cy={positions[index][1]}
            // label={renderCustomizedLabel}
            fill={colors[scenario]}
            onMouseEnter={(_, index) => {setActiveIndex(index)}}
          >
          </Pie>
        ))}
        <Legend
          payload={legendPayload}
          align="right"
          verticalAlign="bottom"
          layout="vertical"
        />
      </PieChart>
    </ResponsiveContainer>
  );
}

export default ChartEnergeticMix;
