import List from '@mui/material/List';
import Typography from '@mui/material/Typography';

import ItemBuildingEfficiency from '../items/ItemBuildingEfficiency';
import ItemEVPercentage from '../items/ItemEVPercentage';
import ItemEnergySource from '../items/ItemEnergySource';
import ItemPVEfficiency from '../items/ItemPVEfficiency';
import ItemSVTEfficiency from '../items/ItemSVTEfficiency';
import ItemSolarCoverage from '../items/ItemSolarCoverage';

import ItemFundingSolar from '../items/ItemFundingSolar';
import ItemFundingThermal from '../items/ItemFundingThermal';

import { Accordion, AccordionSummary, AccordionDetails } from '../Accordions';

const Scenario = ({ name, panel, setExpandedPanel }) => {

  return (
    <>
      {/* Building */}
      <Accordion
        disableGutters={true}
        sx={{ background: '#F3F0F0'}}
        expanded={panel === 'panelBuilding'}
        onChange={setExpandedPanel('panelBuilding')}
      >
        <AccordionSummary
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ color: 'secondary.dark', '& .MuiTypography-root': { fontWeight: 'bold' }}}
        >
          <Typography>Edificio</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List sx={{ overflow: 'auto' }} >
            <ItemBuildingEfficiency scenario={name} />
            <ItemEnergySource scenario={name} />
          </List>
        </AccordionDetails>
      </Accordion>
      {/* Vehicles */}
      <Accordion
        disableGutters={true}
        sx={{ background: '#F3F0F0'}}
        expanded={panel === 'panelVehicles'}
        onChange={setExpandedPanel('panelVehicles')}
      >
        <AccordionSummary
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ color: 'secondary.dark', '& .MuiTypography-root': { fontWeight: 'bold' }}}
        >
          <Typography>Vehículos</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List>
            <ItemEVPercentage scenario={name} />
          </List>
        </AccordionDetails>
      </Accordion>
      {/* Generación */}
      <Accordion
        disableGutters={true}
        sx={{ background: '#F3F0F0'}}
        expanded={panel === 'panelEnergy'}
        onChange={setExpandedPanel('panelEnergy')}
      >
        <AccordionSummary
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ color: 'secondary.dark', '& .MuiTypography-root': { fontWeight: 'bold' }}}
        >
          <Typography>Generación de energía</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List>
            <ItemSolarCoverage scenario={name} />
          </List>
        </AccordionDetails>
      </Accordion>
      {/* Subvención */}
      <Accordion
        disableGutters={true}
        sx={{ background: '#F3F0F0'}}
        expanded={panel === 'panelFunding'}
        onChange={setExpandedPanel('panelFunding')}
      >
        <AccordionSummary
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ color: 'secondary.dark', '& .MuiTypography-root': { fontWeight: 'bold' }}}
        >
          <Typography>Subvenciones</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List>
            <ItemFundingSolar scenario={name} />
            <ItemFundingThermal scenario={name} />
          </List>
        </AccordionDetails>
      </Accordion>
    </>
  );
}

export default Scenario;
