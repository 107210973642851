import { useState } from 'react';
import { useSelector } from 'react-redux';

import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';

import ScenariosTabs from './Scenarios';
import CommonSettings from './CommonSettings';
import CommonSettingsNeighbourhood from './CommonSettingsNeighbourhood';

import { selectSimulationType } from '../../features/commonDataSlice';

const Settings = () => {
  const simulationType = useSelector(selectSimulationType);
  const [expanded, setExpanded] = useState("panelCommonBuilding");
  
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Stack spacing={2} justifyContent="space-between" >
      <Card
        variant="outlined"
        sx={{
          borderColor: "primary.main",
          borderWidth: "medium",
          borderRadius: 2,
          height: 'auto',
        }}
      >
        { simulationType === 'building'
            ? <CommonSettings
                panel={expanded}
                setExpandedPanel={handleChange}
              />
            : <CommonSettingsNeighbourhood
                panel={expanded}
                setExpandedPanel={handleChange}
              />
        }
      </Card>
      <Card
        variant="outlined"
        sx={{
          borderColor: "primary.main",
          borderWidth: "medium",
          borderRadius: 2,
        }}
      >
        <ScenariosTabs panel={expanded} setExpandedPanel={handleChange} />
      </Card>
    </Stack>
  );
};

export default Settings;
