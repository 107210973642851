import { useState } from 'react';
import { connect } from 'react-redux';

import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardActionArea from '@mui/material/CardActionArea';
import Typography from '@mui/material/Typography';


import { setType } from '../features/commonDataSlice';


const ChooseScreen = ({ dispatch }) => {
  const [focused, setFocused] = useState(0)

  const setSimulationType = (value) => {
    dispatch(setType(value));
  };

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <Stack
        spacing={2}
        direction="row"
        justifyContent='space-evenly'
        alignItems='center'
        sx={{ padding: 2, height: '100%' }}
      >
        <Stack
          spacing={2}
          sx={{width: '100%', height: '100%'}}
          alignItems='center'
          justifyContent='center'
        >
          <Card
            sx={{
              width: '80%',
              height: '70%',
              outlineStyle: 'solid',
              outlineColor: '#DCDC00',
              outlineWidth: focused === 1 ? '8px' : 0,
              borderRadius: 2
            }}
          >
            <CardActionArea sx={{height:"100%"}}>
            <CardMedia
              elevation={24}
              component="img"
              height="100%"
              image="/images/neighbourhood.jpg"
              title={"PED"}
              alt="PED"
              sx={{objectFit: "cover", cursor: 'pointer' }}
              onMouseOver={() => {setFocused(1)}}
              onMouseOut={() => {setFocused(0)}}
              onClick={() => {setSimulationType('ped')}}
            />
            </CardActionArea>
          </Card> 
          <Typography
            color="secondary.dark"
            variant="h4"
            sx={{ cursor: "pointer" }}
            onMouseOver={() => {setFocused(1)}}
            onMouseOut={() => {setFocused(0)}}
            onClick={() => {setSimulationType('ped')}}
          >
            Barrio
          </Typography>
        </Stack>
        <Stack
          spacing={2}
          sx={{width: '100%', height: '100%'}}
          alignItems='center'
          justifyContent='center'
        >
          <Card
            sx={{
              width: '80%',
              height: '70%',
              outlineStyle: 'solid',
              outlineColor: '#DCDC00',
              outlineWidth: focused === 2 ? '8px' : 0,
              borderRadius: 2
            }}
          >
            <CardActionArea sx={{height:"100%"}}>
            <CardMedia
              component="img"
              height="100%"
              image="/images/building.jpg"
              title={"Building"}
              alt="Building"
              sx={{objectFit: "cover", cursor: 'pointer'}}
              onMouseOver={() => {setFocused(2)}}
              onMouseOut={() => {setFocused(0)}}
              onClick={() => {setSimulationType('building')}}
            />
            </CardActionArea>
          </Card> 
          <Typography
            color="secondary.dark"
            variant="h4"
            sx={{ cursor: "pointer" }}
            onMouseOver={() => {setFocused(2)}}
            onMouseOut={() => {setFocused(0)}}
            onClick={() => {setSimulationType('building')}}
          >
            Edificio
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
};

export default connect()(ChooseScreen);
