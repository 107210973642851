import { createSelector } from '@reduxjs/toolkit'
import * as config from '../config/values';
import {
  selectPeople,
  selectTotalApartments,
  selectBuildingTotalArea,
  selectIsScenario2Enabled
} from './commonDataSlice';
import {
  selectAnnualGeneration,
  selectMonthlyGenerationPrimaryEnergy,
  selectAnnualElectricalGeneration,
  selectMonthlyElectricalGeneration
} from './generationsSelectors.js';
import {
  selectAnnualConsumptions,
  selectMonthlyConsumptionsPrimaryEnergy,
  selectAnnualAppliancesConsumption,
  selectAnnualConsumptionsWithVehicles,
  selectMonthlyConsumptionsWithVehicles,
  selectAnnualElectricalOnlyConsumptions,
  selectMonthlyElectricalOnlyConsumptions,
  selectAnnualElectricalFullConsumptions,
  selectMonthlyElectricalFullConsumptions
} from './consumptionsSelectors.js';
import {
  selectAnnualDHWNeeds,
  selectAnnualHeatingNeeds,
  selectAnnualCoolingNeeds
} from './needsSelectors.js';
import {
  selectAnnualConsumptionElectricVehicles,
  selectAnnualConsumptionCombustionVehicles
} from './vehiclesSelectors.js';
import {
  selectAnnualPVEmissions,
  selectAnnualThermalEmissions,
  selectAnnualAppliancesEmmisions,
  selectAnnualTransportationEmissions,
} from './emissionsSelectors.js';
import {
  selectFinancialInversion,
  selectFinancialIndicators
} from './financialSelectors.js';


// selectMonthlyEnergyBalance

export const selectMonthlyEnergyBalance = createSelector(
  selectIsScenario2Enabled,
  selectMonthlyGenerationPrimaryEnergy,
  selectMonthlyConsumptionsPrimaryEnergy,
  (scen2Enabled, generation, consumption) => {
    console.log('generation', generation);
    console.log('consumption', consumption);
    const scenarios = scen2Enabled
      ? ['Base', 'Escenario 1', 'Escenario 2']
      : ['Base', 'Escenario 1'];
    const data = config.months.map(month => ({ month: month }));
    scenarios.forEach(scenario => {
      data.forEach((m, i) => {
        m[scenario] = generation[scenario][i] - consumption[scenario][i];
        m[scenario] = m[scenario] / 1000;
      });
    });
    console.log("EB month", data);
    return data;
  }
);

// selectAnnualEnergyBalance

export const selectAnnualEnergyBalance = createSelector(
  selectIsScenario2Enabled,
  selectAnnualGeneration,
  selectAnnualConsumptions,
  (scen2Enabled, generation, consumption) => {
    const scenarios = scen2Enabled
      ? ['Base', 'Escenario 1', 'Escenario 2']
      : ['Base', 'Escenario 1'];
    const colors = { 'Base': '#931B80', 'Escenario 1': '#2DAAE2', 'Escenario 2': '#DCDC00'};
    const data = scenarios.map(scenario => ({ name: scenario, fill: colors[scenario] }));
    data.forEach(scenario => {
      const name = scenario.name;
      scenario.balance = generation[name] - consumption[name]
      scenario.balance = scenario.balance / 1000;
    });
    return data;
  }
);

export const selectInfoEnergyBalance = createSelector(
  selectIsScenario2Enabled,
  selectAnnualGeneration,
  selectAnnualConsumptions,
  selectAnnualConsumptionsWithVehicles,
  (scen2Enabled, gen, consump, fullConsump) => {
    const scenarios = scen2Enabled
      ? ['Base', 'Escenario 1', 'Escenario 2']
      : ['Base', 'Escenario 1'];
    const data = {};
    for (const name of scenarios) {
      data[name] = {};
      const scenario = data[name];
      scenario.balance = gen[name] - consump[name];
      scenario.fullBalance = gen[name] - fullConsump[name];
      scenario.positivity = gen[name] * 100 / fullConsump[name];
      scenario.buildingPositivity = gen[name] * 100 / consump[name];
    };
    return data;
  }
);

// selectMonthlyEnergyBalanceFull

export const selectMonthlyEnergyBalanceFull = createSelector(
  selectIsScenario2Enabled,
  selectMonthlyGenerationPrimaryEnergy,
  selectMonthlyConsumptionsWithVehicles,
  (scen2Enabled, generation, consumption) => {
    const scenarios = scen2Enabled
      ? ['Base', 'Escenario 1', 'Escenario 2']
      : ['Base', 'Escenario 1'];
    const data = config.months.map(month => ({ month: month }));
    scenarios.forEach(scenario => {
      data.forEach((m, i) => {
        m[scenario] = generation[scenario][i] - consumption[scenario][i]
        m[scenario] = m[scenario] / 1000;
      });
    });
    return data;
  }
);

// selectAnnualEnergyBalanceFull

export const selectAnnualEnergyBalanceFull = createSelector(
  selectIsScenario2Enabled,
  selectAnnualGeneration,
  selectAnnualConsumptionsWithVehicles,
  (scen2Enabled, generation, consumption) => {
    const scenarios = scen2Enabled
      ? ['Base', 'Escenario 1', 'Escenario 2']
      : ['Base', 'Escenario 1'];
    const colors = { 'Base': '#931B80', 'Escenario 1': '#2DAAE2', 'Escenario 2': '#DCDC00'};
    const data = scenarios.map(scenario => ({ name: scenario, fill: colors[scenario] }));
    data.forEach(scenario => {
      const name = scenario.name;
      scenario.balance = generation[name] - consumption[name]
      scenario.balance = scenario.balance / 1000;
    });
    return data;
  }
);

// selectMonthlyElectricBalance

export const selectMonthlyElectricalBalance = createSelector(
  selectIsScenario2Enabled,
  selectMonthlyElectricalGeneration,
  selectMonthlyElectricalOnlyConsumptions,
  (scen2Enabled, generation, consumption) => {
    const scenarios = scen2Enabled
      ? ['Base', 'Escenario 1', 'Escenario 2']
      : ['Base', 'Escenario 1'];
    const data = config.months.map(month => ({ month: month }));
    scenarios.forEach(scenario => {
      data.forEach((m, i) => {
        m[scenario] = generation[scenario][i] - consumption[scenario][i]
        m[scenario] = m[scenario] / 1000;
      });
    });
    return data;
  }
);

export const selectMonthlyElectricalBalanceFull = createSelector(
  selectIsScenario2Enabled,
  selectMonthlyElectricalGeneration,
  selectMonthlyElectricalFullConsumptions,
  (scen2Enabled, generation, consumption) => {
    const scenarios = scen2Enabled
      ? ['Base', 'Escenario 1', 'Escenario 2']
      : ['Base', 'Escenario 1'];
    const data = config.months.map(month => ({ month: month }));
    scenarios.forEach(scenario => {
      data.forEach((m, i) => {
        m[scenario] = generation[scenario][i] - consumption[scenario][i]
        m[scenario] = m[scenario] / 1000;
      });
    });
    return data;
  }
);

// selectAnnualElectricEnergyBalance

export const selectAnnualElectricalBalance = createSelector(
  selectIsScenario2Enabled,
  selectAnnualElectricalGeneration,
  selectAnnualElectricalOnlyConsumptions,
  (scen2Enabled, generation, consumption) => {
    const scenarios = scen2Enabled
      ? ['Base', 'Escenario 1', 'Escenario 2']
      : ['Base', 'Escenario 1'];
    const colors = { 'Base': '#931B80', 'Escenario 1': '#2DAAE2', 'Escenario 2': '#DCDC00'};
    const data = scenarios.map(scenario => ({ name: scenario, fill: colors[scenario] }));
    data.forEach(scenario => {
      const name = scenario.name;
      scenario.balance = generation[name] - consumption[name]
      scenario.balance = scenario.balance / 1000;
    });
    return data;
  }
);

export const selectAnnualElectricalBalanceFull = createSelector(
  selectIsScenario2Enabled,
  selectAnnualElectricalGeneration,
  selectAnnualElectricalFullConsumptions,
  (scen2Enabled, generation, consumption) => {
    const scenarios = scen2Enabled
      ? ['Base', 'Escenario 1', 'Escenario 2']
      : ['Base', 'Escenario 1'];
    const colors = { 'Base': '#931B80', 'Escenario 1': '#2DAAE2', 'Escenario 2': '#DCDC00'};
    const data = scenarios.map(scenario => ({ name: scenario, fill: colors[scenario] }));
    data.forEach(scenario => {
      const name = scenario.name;
      scenario.balance = generation[name] - consumption[name]
      scenario.balance = scenario.balance / 1000;
    });
    return data;
  }
);

export const selectInfoElectricalBalance = createSelector(
  selectIsScenario2Enabled,
  selectAnnualElectricalGeneration,
  selectAnnualElectricalOnlyConsumptions,
  (scen2Enabled, gen, consump) => {
    const scenarios = scen2Enabled
      ? ['Base', 'Escenario 1', 'Escenario 2']
      : ['Base', 'Escenario 1'];
    const data = {};
    for (const name of scenarios) {
      data[name] = {};
      const scenario = data[name];
      scenario.balance = gen[name] - consump[name];
      scenario.positivity = gen[name] * 100 / consump[name];
    };
    return data;
  }
);

// selectEnergeticMix

export const selectEnergeticMix = createSelector(
  selectIsScenario2Enabled,
  selectAnnualAppliancesConsumption,
  selectAnnualConsumptionElectricVehicles,
  selectAnnualConsumptionCombustionVehicles,
  selectAnnualDHWNeeds,
  selectAnnualHeatingNeeds,
  selectAnnualCoolingNeeds,
  (
      scen2Enabled,
      appliances,
      evConsumption,
      fuelConsumption,
      heatingNeeds,
      dhwNeeds,
      coolingNeeds
  ) => {
    const scenarios = scen2Enabled
      ? ['Base', 'Escenario 1', 'Escenario 2']
      : ['Base', 'Escenario 1'];
    const data = {};
    scenarios.forEach(scenario => {
      data[scenario] = [];
      data[scenario].push({
        name: "Calefacción",
        abbr: "Calef.",
        value: heatingNeeds[scenario]
      });
      data[scenario].push({
        name: "Agua caliente",
        abbr: "ACS",
        value: dhwNeeds[scenario]
      });
      data[scenario].push({
        name: "Climatización",
        abbr: "Clim.",
        value: coolingNeeds[scenario]
      });
      data[scenario].push({
        name: "Electrodomésticos",
        abbr: "Elect.",
        value: appliances 
      });
      data[scenario].push({
        name: "Coches gasolina",
        abbr: "V. comb.",
        value: fuelConsumption[scenario]
      });
      data[scenario].push({
        name: "Coches eléctricos",
        abbr: "V. elect.",
        value: evConsumption[scenario]
      });
    });
    return data;
  }
);

// selectAnnualCO2

export const selectAnnualCO2 = createSelector(
  selectIsScenario2Enabled,
  selectAnnualThermalEmissions,
  selectAnnualAppliancesEmmisions,
  selectAnnualTransportationEmissions,
  selectAnnualPVEmissions,
  (scen2Enabled, thermalCO2, applianceCO2, transportCO2, pvCO2) => {
    const scenarios = scen2Enabled
      ? ['Base', 'Escenario 1', 'Escenario 2']
      : ['Base', 'Escenario 1'];
    const data = scenarios.map(scenario => ({
      name: scenario,
      "Thermal": thermalCO2[scenario],
      "Transportation": transportCO2[scenario],
      "Appliances": applianceCO2,
      "PV": pvCO2[scenario]
    }));
    console.log(data);
    return data;
  }
);

export const selectTotalCO2 = createSelector(
  selectAnnualCO2,
  selectTotalApartments,
  selectBuildingTotalArea,
  selectPeople,
  (co2, apart, area, habs) => {
    console.log("selectTotalCO2", habs);

    const escenarios = {};
    for (const scen of co2) {
      console.log("scen", scen);
      const total = scen.Thermal + scen.Transportation + scen.Appliances;
      escenarios[scen.name] = {
        name: scen.name,
        total: total,
        perArea: total/area,
        perHab: total/habs
      }
    }
    return escenarios;
  }
);

// selectFinancialBalance

export const selectInvestmentResults = createSelector(
  selectFinancialInversion,
  (investment) => {
    console.log('investment', investment);
    const scenarios = ['Escenario 1', 'Escenario 2'];
    const data = [];
    const year = new Date().getFullYear();
    for (let i=0; i<config.financial.years; i++) {
      const elem = { name: `${year+i}` };
      for (const scen of scenarios) {
        elem[scen] = investment[scen][i];
      }
      data.push(elem);
    }
    return data;
  }
);

export const selectInfoFinancialIndicators = createSelector(
  selectFinancialIndicators,
  (indicators) => {
    console.log("indicators", indicators);
    const escenarios = {};
    for (const name of Object.keys(indicators)) {
      const scen = indicators[name];
      console.log("scen", scen);
      escenarios[name] = {
        name: name,
        investment: scen.investment,
        costs: scen.costs,
        discountedPayback: scen.time,
        payback: scen.investment / (scen.cashFlows * config.financial.interest),
        roi: (scen.cashFlows - scen.costs) * 100 / scen.investment
      }
    }
    return escenarios;
  }
);
