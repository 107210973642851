import { connect, useSelector } from 'react-redux';

import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';

import {
  setScenario2Enable,
  selectIsScenario2Enabled
} from '../../../features/commonDataSlice';

const AddScenarioFab = ({ dispatch }) => {
  const isScenario2Enabled = useSelector(selectIsScenario2Enabled);
  console.log("AddScenarioFab", isScenario2Enabled);
    
  return <Fab
      size="small"
      color="secondary"
      aria-label="add"
      onClick={() => dispatch(setScenario2Enable(true))}
      sx={{
        position: 'absolute',
        top: 3,
        right: 16,
        visibility: isScenario2Enabled ? 'hidden' : 'visible'
      }}
    >
      <AddIcon />
    </Fab>
};

export default connect()(AddScenarioFab);
