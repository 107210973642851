import { connect, useSelector } from 'react-redux';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Slider from '@mui/material/Slider';

import {
  setSolarInstFunding,
  selectSolarInstFunding
} from '../../../features/scenariosSlice';

const ItemFundingSolar = ({ scenario, dispatch }) => {
  const solarInstFunding = useSelector(state => {
    return selectSolarInstFunding(state, scenario);
  });

  const update = (value) => {
    console.log("update in itemEfficiency", value)
    dispatch(setSolarInstFunding({ scenario: scenario, solarInstFunding: value }));
  };

  return (
    <ListItem sx={{ flexWrap: 'wrap' }} disabled={scenario === "Base"}>
      <ListItemText
        id="switch-list-label-pv-efficiency"
        primary="Subvención de instalaciones solares:"
        sx={{ width: '200px' }}
      />
        <Slider
          value={solarInstFunding}
          onChange={(v) => update(v.target.value)}
          min={0}
          max={100}
          valueLabelDisplay="auto"
          valueLabelFormat={(value) => `${value}%`}
        />
    </ListItem>
  );
};

export default connect()(ItemFundingSolar);
