import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
} from 'recharts';

import { useSelector } from 'react-redux';
import { selectMonthlyElectricalBalance } from '../../features/chartSelectors';


const ChartMonthlyElectricalBalance = () => {
  const data = useSelector(selectMonthlyElectricalBalance);
  const formatTooltip = (value) => {
    return value.toFixed(2);
  };

  return (
    <ResponsiveContainer width="99%" height="99%">
        <BarChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <XAxis dataKey="month" />
          <YAxis orientation="right"/>
          <Tooltip formatter={formatTooltip} />
          <Legend iconType="square"/>
          <ReferenceLine y={0} stroke="#000" />
          <Bar dataKey="Base" fill="#931B80" unit=" MWh"/>
          <Bar dataKey="Escenario 1" fill="#2DAAE2" unit=" MWh"/>
          {data[0]["Escenario 2"] &&
            <Bar dataKey="Escenario 2" fill="#DCDC00" unit=" MWh"/>
          }
        </BarChart>
      </ResponsiveContainer>
  );
}

export default ChartMonthlyElectricalBalance;
