import { useCallback, useState } from 'react'
import useMediaQuery from '@mui/material/useMediaQuery';
import Picker  from 'react-mobile-picker'
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';


const CombinationLockInput = ({ num, isOpen, setIsOpen, update, text }) => {
  const [pickerValue, setPickerValue] = useState(
    [...Array(num.length).keys()].reduce((o, i) => {
        console.log("i", i, o)
        o['d' + (num.length - i -1)] = num[i]; return o;
    }, {})
  );
  const fullScreen = useMediaQuery(theme => theme.breakpoints.down("md"));
  console.log("num", num, pickerValue);

  const handlePickerChange = useCallback((newValue, key) => {
    setPickerValue({ ...newValue })
  }, [])

  const updateValue = () => {
    const stringValue = Object.values(pickerValue).join('');
    const newValue = parseInt(stringValue, 10);
    console.log("update", newValue);
    update(newValue);

    setIsOpen(false);
  };

  return (
    <Dialog
      onClose={updateValue}
      open={isOpen}
      fullScreen={fullScreen}
    >
      <DialogTitle sx={{ m: 0, p: 2, width: fullScreen ? 'auto' : '350px'}}>{text}</DialogTitle>
      <DialogContent sx={{ width: fullScreen ? 'auto' : '350px'}}dividers>
        <Picker
          value={pickerValue}
          onChange={handlePickerChange}
          wheelMode="natural"
        >
          {Object.entries(pickerValue).map(item => (
            <Picker.Column name={item[0]} key={item[0]}>
              { Array.from({ length: 10 }, (_, i) => String(i)).map((d) => (
                <Picker.Item key={d} value={d}>
                  {({ selected }) => (
                    <div
                      className={selected ? 'number-selected' : 'number-not-selected'}
                    >
                      {d}
                    </div>
                  )}
                </Picker.Item>
              ))}
            </Picker.Column>
          ))}
        </Picker>
        <DialogActions>
          <Button autoFocus onClick={updateValue} >
            OK
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
};

export default CombinationLockInput;
