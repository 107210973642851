import { connect, useSelector } from 'react-redux';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Slider from '@mui/material/Slider';

import {
  setPvEfficiency,
  selectEnergyPvEfficiency
} from '../../../features/scenariosSlice';

const ItemEVPercentage = ({ scenario, dispatch }) => {
  const pvEfficiency = useSelector(state => {
    return selectEnergyPvEfficiency(state, scenario);
  });

  const update = (value) => {
    console.log("update in itemEfficiency", value)
    dispatch(setPvEfficiency({ scenario: scenario, pvEfficiency: value }));
  };

  return (
    <ListItem sx={{ flexWrap: 'wrap' }} disabled={scenario === "Base"}>
      <ListItemText
        id="switch-list-label-pv-efficiency"
        primary="Eficencia paneles solares fotovoltáicos:"
        sx={{ width: '200px' }}
      />
        <Slider
          value={pvEfficiency}
          onChange={(v) => update(v.target.value)}
          min={0}
          max={100}
          valueLabelDisplay="auto"
          valueLabelFormat={(value) => `${value}%`}
        />
    </ListItem>
  );
};

export default connect()(ItemEVPercentage);
