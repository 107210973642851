import { connect, useSelector } from 'react-redux';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Slider from '@mui/material/Slider';

import {
  updateResidentialPercentage,
  selectResidentialPercentage
} from '../../../features/commonDataSlice';

const ItemBuildingMix = ({ scenario, dispatch }) => {
  const percentage = useSelector(selectResidentialPercentage);

  const update = (value) => {
    console.log("update in itemBuildingMix", value)
    dispatch(updateResidentialPercentage(value));
  };

  return (
    <ListItem sx={{ flexWrap: 'wrap' }} disabled={scenario === "Base"}>
      <ListItemText
        id="switch-list-label-percentage"
        primary="Porcentaje de edificios residenciales:"
        sx={{ width: '200px' }}
      />
      <Slider
        value={percentage}
        onChange={(v) => update(v.target.value)}
        min={0}
        max={100}
        valueLabelDisplay="auto"
        valueLabelFormat={(value) => `${value}%`}
      />
    </ListItem>
  );
};

export default connect()(ItemBuildingMix);
