import { useSelector } from 'react-redux';

import Grid from '@mui/material/Unstable_Grid2';

import { selectIsScenario2Enabled } from '../../../features/commonDataSlice.js';

import EBScenarioColumn from './EBScenarioColumn';

const InfoElectricalBalance = () => {
  const isScen2Enabled = useSelector(selectIsScenario2Enabled);
    
  return (
    <Grid container spacing={2}>
      <Grid xs={isScen2Enabled ? 4 : 6} >
        <EBScenarioColumn escenario="Base" />
      </Grid>
      <Grid xs={isScen2Enabled ? 4 : 6} >
        <EBScenarioColumn escenario="Escenario 1" />
      </Grid>
      {isScen2Enabled &&
        <Grid xs={4} >
          <EBScenarioColumn escenario="Escenario 2" />
        </Grid>
      }
    </Grid>
  );
};

export default InfoElectricalBalance;
