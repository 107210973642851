import { createSelector } from '@reduxjs/toolkit'
import * as config from '../config/values';
import {
  selectTotalApartments,
  selectApartmentAnnualPowerConsumption
} from './commonDataSlice';
import { selectEnergyPrimarySource } from './scenariosSlice';
import {
  selectMonthlyDHWNeedsScenario,
  selectMonthlyHeatingNeedsScenario,
  selectMonthlyCoolingNeedsScenario
} from './needsSelectors';
import {
  selectMonthlyConsumptionElectricVehiclesScenario,
  selectMonthlyConsumptionCombustionVehiclesScenario
} from './vehiclesSelectors';
import {
  selectMonthlyGasNaturalGenerationScenario
} from './generationsSelectors';


// Appliances

export const selectMonthlyAppliancesConsump = createSelector(
  selectTotalApartments,
  selectApartmentAnnualPowerConsumption,
  (num_apartments, annual_consumption) => {

    return config.days.map(d => {
      return num_apartments * annual_consumption * d / 365;
    });
  }
);

export const selectAppliancesConsumpInMonth = createSelector(
  (state, month_index) => month_index,
  selectMonthlyAppliancesConsump,
  (month, monthlyConsumption) => {
    return monthlyConsumption[month];
  }
);

export const selectAnnualAppliancesConsumption = createSelector(
  selectTotalApartments,
  selectApartmentAnnualPowerConsumption,
  (num_apartments, annual_consumption) => {
     console.log('appliances consump', num_apartments, annual_consumption);
     return num_apartments * annual_consumption 
  }
);

// Heat Pumps

export const selectMonthlyDHWHPConsumpScenario = createSelector(
  (state, scenario, type) => type,
  selectMonthlyDHWNeedsScenario,
  (type, dhw_needs) => {
    return dhw_needs.map(m => {
      return m / config.CoPs[type]["DHW"]
    });
  }
);

export const selectMonthlyHeatingHPConsumpScenario = createSelector(
  (state, scenario, type) => type,
  selectMonthlyHeatingNeedsScenario,
  (type, heating_needs) => {
    return heating_needs.map(m => {
      return m / config.CoPs[type]["Heating"]
    });
  }
);

export const selectMonthlyCoolingHPConsumpScenario = createSelector(
  (state, scenario, type) => type,
  selectMonthlyCoolingNeedsScenario,
  (type, cooling_needs) => {
    return cooling_needs.map(m => {
      return m / config.CoPs[type]["Cooling"];
    });
  }
);

export const selectMonthlyHPConsumpScenario = createSelector(
  selectEnergyPrimarySource,
  selectMonthlyDHWHPConsumpScenario,
  selectMonthlyHeatingHPConsumpScenario,
  selectMonthlyCoolingHPConsumpScenario,
  (state, scenario, sourceType) => sourceType,
  (primarySource, dhw, heating, cooling, sourceType) => {
     console.log('monthly cool. consump', cooling);
    if (primarySource === sourceType) {
      return dhw.map((val, index) => {
        return val + heating[index] + cooling[index];
      });
    }
    return Array(12).fill(0);
  }
);

export const selectHPConsumpInMonthScenario = createSelector(
  (state, month_index) => month_index,
  selectMonthlyHPConsumpScenario,
  (month, monthlyConsump) => {
    return monthlyConsump[month];
  }
);

export const selectAnnualHPConsumpScenario = createSelector(
  selectMonthlyHPConsumpScenario,
  (monthlyConsump) => {
    return monthlyConsump.reduce((acc, month_consump) => {
      return acc + month_consump;
    }, 0);
  }
);

// Gas Natural

export const selectMonthlyGasNaturalConsumptionScenario = createSelector(
  selectMonthlyGasNaturalGenerationScenario,
  (gas) => {

    return gas.map((monthlyGas, index) => {
      return monthlyGas;
    });
  }
);

export const selectGasNaturalConsumptionsInMonthScenario = createSelector(
  (state, month_index) => month_index,
  selectMonthlyGasNaturalConsumptionScenario,
  (month, monthlyConsump) => {
    return monthlyConsump[month];
  }
);

export const selectAnnualGasNaturalConsumptionsScenario = createSelector(
  selectMonthlyGasNaturalConsumptionScenario,
  (monthlyConsump) => {
    return monthlyConsump.reduce((acc, month_consump) => {
      return acc + month_consump;
    }, 0);
  }
);

// Electrical Consumptions

export const selectMonthlyElectricalConsumptionsScenario = createSelector(
  selectMonthlyAppliancesConsump,
  (state, scenario) => selectMonthlyHPConsumpScenario(state, scenario, 'geotermia'),
  (state, scenario) => selectMonthlyHPConsumpScenario(state, scenario, 'aerotermia'),
  (appliances, geo, aero) => {
    console.log("elec - appliances", appliances);
    console.log("elec - geo", geo);
    console.log("elec - aero", aero);
    return geo.map((val, index) => {
      return val + aero[index] + appliances[index];
    });
  }
);

export const selectElectricalConsumptionsInMonthScenario = createSelector(
  (state, month_index) => month_index,
  selectMonthlyElectricalConsumptionsScenario,
  (month, monthlyConsump) => {
    return monthlyConsump[month];
  }
);

export const selectAnnualElectricalConsumptionsScenario = createSelector(
  selectMonthlyElectricalConsumptionsScenario,
  (monthlyConsump) => {
    return monthlyConsump.reduce((acc, month_consump) => {
      return acc + month_consump;
    }, 0);
  }
);

// Eletrical Consumptions + EVs

export const selectMonthlyElectricalConsumptionsWithEVsScenario = createSelector(
  selectMonthlyElectricalConsumptionsScenario,
  selectMonthlyConsumptionElectricVehiclesScenario,
  (electricity, evs) => {
    return electricity.map((val, index) => {
      return val + evs[index];
    });
  }
);

export const selectElectricalConsumptionsWithEVsInMonthScenario = createSelector(
  (state, month_index) => month_index,
  selectMonthlyElectricalConsumptionsWithEVsScenario,
  (month, monthlyConsump) => {
    return monthlyConsump[month];
  }
);

export const selectAnnualElectricalConsumptionsWithEVsScenario = createSelector(
  selectMonthlyElectricalConsumptionsWithEVsScenario,
  (monthlyConsump) => {
    return monthlyConsump.reduce((acc, month_consump) => {
      return acc + month_consump;
    }, 0);
  }
);

// Consumptions

export const selectMonthlyConsumptionsScenario = createSelector(
  selectMonthlyElectricalConsumptionsScenario,
  selectMonthlyGasNaturalConsumptionScenario,
  selectMonthlyDHWNeedsScenario,
  selectMonthlyHeatingNeedsScenario,
  selectMonthlyCoolingNeedsScenario,
  (hp, gasNatural, dhw, heating, cooling) => {
    console.log('BAL: -CONSUMPTION', 'gas', gasNatural, 'elect', hp, 'DHW needs', dhw,
      'heating needs', heating, 'cooling needs', cooling
    );
    return hp.map((val, index) => {
      return val + gasNatural[index] + dhw[index] + heating[index] + cooling[index];
    });
  }
);

export const selectConsumptionsInMonthScenario = createSelector(
  (state, month_index) => month_index,
  selectMonthlyConsumptionsScenario,
  (month, monthlyConsump) => {
    return monthlyConsump[month];
  }
);

export const selectAnnualConsumptionsScenario = createSelector(
  selectMonthlyConsumptionsScenario,
  (monthlyConsump) => {
    return monthlyConsump.reduce((acc, month_consump) => {
      return acc + month_consump;
    }, 0);
  }
);

export const selectMonthlyConsumptions = createSelector(
  state => selectMonthlyConsumptionsScenario(state, 'Base'),
  state => selectMonthlyConsumptionsScenario(state, 'Escenario 1'),
  state => selectMonthlyConsumptionsScenario(state, 'Escenario 2'),
  (base, scenario1, scenario2) => {
    return {
      Base: base,
      'Escenario 1': scenario1,
      'Escenario 2': scenario2 
    };
  }
);

export const selectAnnualConsumptions = createSelector(
  state => selectAnnualConsumptionsScenario(state, "Base"),
  state => selectAnnualConsumptionsScenario(state, "Escenario 1"),
  state => selectAnnualConsumptionsScenario(state, "Escenario 2"),
  (base, scenario1, scenario2) => {
    return {
      Base: base,
      'Escenario 1': scenario1,
      'Escenario 2': scenario2 
    };
  }
);

// Consumptions (Primary Energy)

export const selectMonthlyConsumptionsPrimaryEnergyScenario = createSelector(
  selectMonthlyElectricalConsumptionsScenario,
  selectMonthlyGasNaturalConsumptionScenario,
  selectMonthlyDHWNeedsScenario,
  selectMonthlyHeatingNeedsScenario,
  selectMonthlyCoolingNeedsScenario,
  (hp, gasNatural, dhw, heating, cooling) => {
    console.log('BAL: -CONSUMPTION', 'gas', gasNatural, 'elect', hp, 'DHW needs', dhw,
      'heating needs', heating, 'cooling needs', cooling
    );
    return hp.map((val, index) => {
      return val * config.conversionFactors.elecToEnergy
        + gasNatural[index] * config.conversionFactors.gasNaturalToEnergy
        // Needs
        + dhw[index] + heating[index] + cooling[index];
    });
  }
);

export const selectMonthlyConsumptionsPrimaryEnergy = createSelector(
  state => selectMonthlyConsumptionsPrimaryEnergyScenario(state, 'Base'),
  state => selectMonthlyConsumptionsPrimaryEnergyScenario(state, 'Escenario 1'),
  state => selectMonthlyConsumptionsPrimaryEnergyScenario(state, 'Escenario 2'),
  (base, scenario1, scenario2) => {
    return {
      Base: base,
      'Escenario 1': scenario1,
      'Escenario 2': scenario2 
    };
  }
);



// Consumptions + Vehicles

export const selectMonthlyConsumptionsWithVehiclesScenario = createSelector(
  selectMonthlyConsumptionsPrimaryEnergyScenario,
  selectMonthlyConsumptionElectricVehiclesScenario,
  selectMonthlyConsumptionCombustionVehiclesScenario,
  (consump, evs, cvs) => {
    return consump.map((val, index) => {
      return val + evs[index] + cvs[index];
    });
  }
);

export const selectConsumptionsWithVehiclesInMonthScenario = createSelector(
  (state, month_index) => month_index,
  selectMonthlyConsumptionsWithVehiclesScenario,
  (month, monthlyConsump) => {
    return monthlyConsump[month];
  }
);

export const selectAnnualConsumptionsWithVehiclesScenario = createSelector(
  selectMonthlyConsumptionsWithVehiclesScenario,
  (monthlyConsump) => {
    return monthlyConsump.reduce((acc, month_consump) => {
      return acc + month_consump;
    }, 0);
  }
);

export const selectMonthlyConsumptionsWithVehicles = createSelector(
  state => selectMonthlyConsumptionsWithVehiclesScenario(state, 'Base'),
  state => selectMonthlyConsumptionsWithVehiclesScenario(state, 'Escenario 1'),
  state => selectMonthlyConsumptionsWithVehiclesScenario(state, 'Escenario 2'),
  (base, scenario1, scenario2) => {
    return {
      Base: base,
      'Escenario 1': scenario1,
      'Escenario 2': scenario2 
    };
  }
);

export const selectAnnualConsumptionsWithVehicles = createSelector(
  state => selectAnnualConsumptionsWithVehiclesScenario(state, "Base"),
  state => selectAnnualConsumptionsWithVehiclesScenario(state, "Escenario 1"),
  state => selectAnnualConsumptionsWithVehiclesScenario(state, "Escenario 2"),
  (base, scenario1, scenario2) => {
    return {
      Base: base,
      'Escenario 1': scenario1,
      'Escenario 2': scenario2 
    };
  }
);

// Electrical Only Consumptions

export const selectMonthlyElectricalOnlyConsumptions = createSelector(
  state => selectMonthlyElectricalConsumptionsScenario(state, 'Base'),
  state => selectMonthlyElectricalConsumptionsScenario(state, 'Escenario 1'),
  state => selectMonthlyElectricalConsumptionsScenario(state, 'Escenario 2'),
  (base, scenario1, scenario2) => {
    console.log("Month Elec Consumptions (only)", base, scenario1, scenario2);
    return {
      Base: base,
      'Escenario 1': scenario1,
      'Escenario 2': scenario2 
    };
  }
);

export const selectAnnualElectricalOnlyConsumptions = createSelector(
  state => selectAnnualElectricalConsumptionsScenario(state, "Base"),
  state => selectAnnualElectricalConsumptionsScenario(state, "Escenario 1"),
  state => selectAnnualElectricalConsumptionsScenario(state, "Escenario 2"),
  (base, scenario1, scenario2) => {
    return {
      Base: base,
      'Escenario 1': scenario1,
      'Escenario 2': scenario2 
    };
  }
);

// Full Electrical Consumptions

export const selectMonthlyElectricalFullConsumptionsScenario = createSelector(
  selectMonthlyElectricalConsumptionsScenario,
  selectMonthlyConsumptionElectricVehiclesScenario,
  (consump, evs) => {
    console.log("monthlyelectricalfull", consump, evs)
    return consump.map((val, index) => {
      return val + evs[index];
    });
  }
);

export const selectAnnualElectricalFullConsumptionsScenario = createSelector(
  selectMonthlyElectricalFullConsumptionsScenario,
  (monthlyConsump) => {
    return monthlyConsump.reduce((acc, month_consump) => {
      return acc + month_consump;
    }, 0);
  }
);

export const selectMonthlyElectricalFullConsumptions = createSelector(
  state => selectMonthlyElectricalFullConsumptionsScenario(state, 'Base'),
  state => selectMonthlyElectricalFullConsumptionsScenario(state, 'Escenario 1'),
  state => selectMonthlyElectricalFullConsumptionsScenario(state, 'Escenario 2'),
  (base, scenario1, scenario2) => {
    return {
      Base: base,
      'Escenario 1': scenario1,
      'Escenario 2': scenario2 
    };
  }
);

export const selectAnnualElectricalFullConsumptions = createSelector(
  state => selectAnnualElectricalFullConsumptionsScenario(state, "Base"),
  state => selectAnnualElectricalFullConsumptionsScenario(state, "Escenario 1"),
  state => selectAnnualElectricalFullConsumptionsScenario(state, "Escenario 2"),
  (base, scenario1, scenario2) => {
    return {
      Base: base,
      'Escenario 1': scenario1,
      'Escenario 2': scenario2 
    };
  }
);
