import { createSlice, createSelector } from '@reduxjs/toolkit'
import * as config from '../config/values';

const initialState = {
  // Type of analysis: it can be 'island' or 'building'
  type: '-', // -, building, ped
  buildingType: 'residential',
  residentialPercentage: 70,
  populationDensity: 6000,
  neighbourhoodSurface: 5,
  area: 85,
  floors: 7,
  doors: 6,  // number of apartments per floor
  // basic power annual consumption (light and household appliences)
  powerConsumption: 108,
  isScenario2Enabled: false,
  // Vehicles
  vehiclesApartment: 1.2,
  vehicleDailyDistance: 10,
  // Chart
  currentChart: 0
};

export const commonDataSlice = createSlice({
  name: 'common',
  initialState: initialState,
  reducers: {
    setType (state, action) {
      state.type = action.payload;
    },
    setBuildingType (state, action) {
      state.buildingType = action.payload;
    },
    updateResidentialPercentage (state, action) {
      state.residentialPercentage = action.payload;
    },
    updateNeighbourhoodArea (state, action) {
      state.neighbourhoodSurface = action.payload;
    },
    updatePopulationDensity (state, action) {
      state.populationDensity = action.payload;
    },
    updateArea (state, action) {
      state.area = action.payload;
    },
    updateFloors (state, action) {
      state.floors = action.payload;
    },
    updateDoors (state, action) {
      state.doors = action.payload;
    },
    updateConsumption (state, action) {
      state.powerConsumption = action.payload;
    },
    updateNumVehicles (state, action) {
      state.numVehicles = action.payload;
    },
    updateDailyDistance (state, action) {
      state.vehicleDailyDistance = action.payload;
    },
    setScenario2Enable (state, action) {
      state.isScenario2Enabled = action.payload;
    },
    updateCurrentChart (state, action) {
      state.currentChart = action.payload;
    }
  }
});

export const {
  setType,
  setBuildingType,
  setScenario2Enable,
  updateArea,
  updateDoors,
  updateFloors,
  updateConsumption,
  updateNumVehicles,
  updateCurrentChart,
  updateDailyDistance,
  updatePopulationDensity,
  updateNeighbourhoodArea,
  updateResidentialPercentage,
} = commonDataSlice.actions;

export const selectBuildingType = state => state.common.buildingType;
export const selectSimulationType = state => state.common.type;
export const selectResidentialPercentage = state => state.common.residentialPercentage;
export const selectTerciaryPercentage = state => {
  return 1 - state.common.residentialPercentage;
};
export const selectNeighbourhoodArea = state => state.common.neighbourhoodSurface;
export const selectPopulationDensity = state => state.common.populationDensity;
export const selectApartmentArea = state => state.common.area;
export const selectBuildingFloors = state => state.common.floors;
export const selectBuildingDoors = state => state.common.doors;
export const selectApartmentAnnualPowerConsumption = state => {
  return state.common.powerConsumption;
};
export const selectIsScenario2Enabled = state => state.common.isScenario2Enabled;
export const selectVehiclesDailyDistance = state => state.common.vehicleDailyDistance;
export const selectVehiclesPerApartment = state => state.common.vehiclesApartment;
export const selectCurrentChart = state => state.common.currentChart;

// Generated selectors
export const selectApartmentsPerBuilding = createSelector(
  state => state.common.floors,
  state => state.common.doors,
  (floors, doors) => {
    return floors * doors;
  }
);

export const selectNumBuildings = createSelector(
  selectSimulationType,
  selectNeighbourhoodArea,
  selectPopulationDensity,
  selectApartmentsPerBuilding,
  (simulation, area, density, apartmentsPerBuilding) => {
    if (simulation === 'building')
      return 1;
    console.log("total apartments", area, density, apartmentsPerBuilding);
    return Math.round(
      area * density / config.density.apartment / apartmentsPerBuilding
    );
  }
);

export const selectTotalApartments = createSelector(
  selectNumBuildings,
  selectApartmentsPerBuilding,
  (buildings, apartmentsPerBuilding) => {
    return buildings * apartmentsPerBuilding;
  }
);

export const selectBuildingTotalArea = createSelector(
  selectTotalApartments,
  state => state.common.area,
  (apartments, area) => {
    return apartments * area;
  }
);

export const selectRoofArea = createSelector(
  state => state.common.doors,
  state => state.common.area,
  (doors, area_door) => {
    return doors * area_door;
  }
);

export const selectNumVehicles = createSelector(
  state => state.common.vehiclesApartment,
  selectTotalApartments,
  (vehiclesPerApartment, apartments) => {
    return vehiclesPerApartment * apartments;
  }
);

export const selectTotalKms = createSelector(
  state => state.common.vehicleDailyDistance,
  (state, days) => days,
  selectNumVehicles,
  (dailyDistance, days, numVehicles) => {
    return dailyDistance * days * numVehicles;
  }
);

export const selectMonthlyTotalKms = createSelector(
  state => state.common.vehicleDailyDistance,
  selectNumVehicles,
  (dailyDistance, numVehicles) => {
    return config.days.map(days => {
      console.log('kms', dailyDistance, days, numVehicles);
      return dailyDistance * days * numVehicles;
    });
  }
);

export const selectPeople = createSelector(
  selectBuildingType,
  selectSimulationType,
  selectTotalApartments,
  selectResidentialPercentage,
  (building, simulation, apartments, percentage) => {
    if (simulation === 'building') {
      return building === 'residential'
        ? config.density.apartment * apartments
        : config.density.office * apartments;
    }
    const residentialBuildings = apartments * percentage/100
    let total = residentialBuildings * config.density.apartment;
    total += (apartments - residentialBuildings) * config.density.office;
    return total;
  }
);



export default commonDataSlice.reducer;
