import { connect, useSelector } from 'react-redux';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Slider from '@mui/material/Slider';

import {
  setPvPercentage,
  setSvtPercentage,
  selectEnergyPvPercentage,
  selectEnergySvtPercentage
} from '../../../features/scenariosSlice';

const ItemEVPercentage = ({ scenario, dispatch }) => {
  const pvCoverage = useSelector(state => {
    return selectEnergyPvPercentage(state, scenario);
  });
  const svtCoverage = useSelector(state => {
    return selectEnergySvtPercentage(state, scenario);
  });

  const updatePvCoverage = (value) => {
    console.log("update in itemSolarCoverage (PV)", value, value[1] - value[0])
    if (value[1] - svtCoverage !== pvCoverage) {
      dispatch(setPvPercentage({
        scenario: scenario,
        pvPercentage: value[1] - svtCoverage
      }));
    } else if (value[0] !== svtCoverage) {
      dispatch(setSvtPercentage({ scenario: scenario, svtPercentage: value[0] }));
    }
  };

  const updateSvtCoverage = (value) => {
    console.log("update in itemSolarCoverage (SVT)", value)
    dispatch(setSvtPercentage({ scenario: scenario, svtPercentage: value }));
  };

  return (
    <>
      <ListItem disabled={scenario === "Base"}>
        <ListItemText
          id="switch-list-label-cobert-panels"
          primary="Porcentaje cobertura paneles solares:"
        />
      </ListItem>
      <ListItem disabled={scenario === "Base"} >
        <ListItemText
          id="switch-list-label-svt-covert"
          primary="▪ térmicos:"
          sx={{ minWidth: '117px', textWrap: 'nowrap' }}
        />
          <Slider
            value={svtCoverage}
            onChange={(v) => updateSvtCoverage(v.target.value)}
            min={0}
            max={100}
            valueLabelDisplay="auto"
            valueLabelFormat={(value) => `${value}%`}
          />
      </ListItem>
      <ListItem disabled={scenario === "Base"} >
        <ListItemText
          id="switch-list-label-svt-covert"
          primary="▪ fotovoltáicos:"
          sx={{ minWidth: '117px', textWrap: 'nowrap' }}
        />
          <Slider
            value={[svtCoverage, svtCoverage+pvCoverage]}
            onChange={(v) => updatePvCoverage(v.target.value)}
            min={0}
            max={100}
            valueLabelDisplay="auto"
            valueLabelFormat={(value) => `${value}%`}
          />
      </ListItem>
    </>
  );
};

export default connect()(ItemEVPercentage);
