import { connect, useSelector } from 'react-redux';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import {
  setPrimarySource,
  selectEnergyPrimarySource
} from '../../../features/scenariosSlice';


const ItemEnergySource = ({ scenario, dispatch }) => {
  const energySource = useSelector(state => {
    return selectEnergyPrimarySource(state, scenario)
  });

  const update = (value) => {
    console.log("update in itemEnergySource", value)
    dispatch(setPrimarySource({ scenario: scenario, primarySource: value }));
  };

  return (
    <ListItem sx={{ flexWrap: 'wrap' }} disabled={scenario === "Base"}>
      <ListItemText
        id="switch-list-label-energySource"
        primary="Energía térmica:"
        secondary="fuente de generación primaria"
        sx={{ width: '200px' }}
      />
      <RadioGroup
        row
        value={energySource}
        onChange={(v) => update(v.target.value)}
        sx={{ justifyContent: 'flex-end' }}
      >
        <FormControlLabel
          value="geotermia"
          control={<Radio />}
          label="Geotermia"
          labelPlacement="start"
        />
        <FormControlLabel
          value="aerotermia"
          control={<Radio />}
          label="Aerotermia"
          labelPlacement="start"
        />
        <FormControlLabel
          value="gasNatural"
          control={<Radio />}
          label="Gas Natural"
          labelPlacement="start"
        />
      </RadioGroup>
    </ListItem>
  );
};

export default connect()(ItemEnergySource);
