import { useState } from 'react'
import { connect, useSelector } from 'react-redux';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

import {
  updateArea,
  selectBuildingType,
  selectApartmentArea
} from '../../../features/commonDataSlice';

import CombinationLockInput from './CombinationLockInput';

const ItemArea = ({ dispatch }) => {
  const area = useSelector(selectApartmentArea);
  const buildingType = useSelector(selectBuildingType);
  const [isOpen, setIsOpen] = useState(false)
  const text = buildingType === 'residential' ? 'apartamento' : 'oficina';

  const update = (value) => {
    console.log("update in itemArea", value)
    dispatch(updateArea(value));
  };

  return (
    <ListItem sx={{ flexWrap: 'wrap' }}>
      <ListItemText
        id="switch-list-label-area"
        primary={`Superficie ${text}:`}
        secondary="media"
        sx={{ minWidth: '173px' }}
      />
      <TextField
        variant="standard"
        value={area}
        inputProps={{ style: { textAlign: 'right' }}}
        InputProps={{
          endAdornment: <InputAdornment position="end">m²</InputAdornment>,
        }}
        sx={{ width: 90, flexShrink: 0 }}
        onClick={() => setIsOpen(true)}
      />
      <CombinationLockInput
        num={String(area).padStart(3, '0')}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        update={update}
        text={`Selecciona la superficie media de cada ${text}:`}
      />
    </ListItem>
  );
};

export default connect()(ItemArea);
