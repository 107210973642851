import { configureStore, combineReducers } from '@reduxjs/toolkit';

import commonDataReducer from '../features/commonDataSlice';
import scenariosReducer from '../features/scenariosSlice';

const resetReducer = (state, action) => {
  if (action.type === 'resetState') {
    return {
      common: commonDataReducer(undefined, {}),
      scenarios: scenariosReducer(undefined, {}),
    };
  }
  return rootReducer(state, action);
};

const rootReducer = combineReducers({
  common: commonDataReducer,
  scenarios: scenariosReducer,
});

const store = configureStore({
  reducer: resetReducer
});

export const resetSimulation = () => ({ type: 'resetState' });

export default store;
