import { useSelector } from 'react-redux';

import Box from '@mui/material/Box';

import { selectCurrentChart } from '../../features/commonDataSlice';

import InfoEmissions from './InfoEmissions';
import InfoEnergyMix from './InfoEnergyMix';
import InfoEnergyBalance from './InfoEnergyBalance';
import InfoElectricalBalance from './InfoElectricalBalance';
import InfoFinancialAnalysis from './InfoFinancialAnalysis';

import ToBeImplemented from '../ToBeImplemented';

const renderInfo = (chart) => {
  console.log("renderInfo", chart);

  switch(chart) {
    case 0: return <InfoEnergyBalance />
    case 1: return <InfoElectricalBalance />
    case 2: return <InfoEnergyMix />
    case 3: return <InfoEmissions />
    case 4: return <InfoFinancialAnalysis />
    default: return <ToBeImplemented />;
  }
}

const InfoPanel = ({ background="#00000020" }) => {
  const tabChart = useSelector(selectCurrentChart);

  return (
    <Box
      sx={{
        zIndex: 9999,
        // position: 'absolute',
        backgroundColor: background,
        padding: 1,
        height: '100%'
      }}
    >
      {renderInfo(tabChart)}
    </Box>
  );
};

export default InfoPanel;
