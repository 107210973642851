import { useState } from 'react'
import { connect, useSelector } from 'react-redux';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';

import {
  updateDoors,
  selectBuildingType,
  selectBuildingDoors
} from '../../../features/commonDataSlice';

import CombinationLockInput from './CombinationLockInput';

const ItemDoors = ({ dispatch }) => {
  const doors = useSelector(selectBuildingDoors);
  const buildingType = useSelector(selectBuildingType);
  const [isOpen, setIsOpen] = useState(false)
  const text = buildingType === 'residential' ? 'apartamentos' : 'oficinas';

  const update = (value) => {
    console.log("update in itemDoors", value)
    dispatch(updateDoors(value));
  };

  return (
    <ListItem sx={{ flexWrap: 'wrap' }}>
      <ListItemText
        id="switch-list-label-doors"
        primary={`Nº ${text}:`}
        secondary="por piso"
        sx={{ minWidth: '173px' }}
      />
      <TextField
        variant="standard"
        value={doors}
        inputProps={{ style: { textAlign: 'right' }}}
        onClick={() => setIsOpen(true)}
        sx={{ width: 90, flexShrink: 0 }}
      />
      <CombinationLockInput
        num={String(doors).padStart(3, '0')}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        update={update}
        text={`Selecciona el número de ${text} por piso:`}
      />
    </ListItem>
  );
};

export default connect()(ItemDoors);
