import { useMediaQuery } from '@mui/material';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const ToBeImplemented = ({ background="#00000020" }) => {
  // const isMediumScreen = useMediaQuery(theme => theme.breakpoints.down("md"));
  const isMediumScreen = useMediaQuery('(max-width:480px)');

  return (
    <Box
      sx={{
        zIndex: 9999,
        // position: 'absolute',
        backgroundColor: background,
        height: '100%'
      }}
    >
      <Typography
        variant={isMediumScreen ? "h3" : "h1"}
        color="primary"
        sx={{
          pl: isMediumScreen ? 9 : 0,
          minWidth: '100px',
          wordWrap: 'break-word'
        }}
      >
        To be implemented
      </Typography>
    </Box>
  );
}

export default ToBeImplemented;
