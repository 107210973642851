import { createSlice, createSelector } from '@reduxjs/toolkit'
import { selectIsScenario2Enabled } from './commonDataSlice';

const getBaseScenarioState = () => {
  return {
    efficiency: 'F',
    vehicles: {
      evPercentage: 0,
    },
    energy: {
      primarySource: 'gasNatural',
      svtEfficiency: 20,
      pvEfficiency: 22.8,
      svtPercentage: 0,
      pvPercentage: 0,
    },
    funding: {
      thermalInstalations: 30,
      solarInstalations: 30,
    }
  };
}

const initialState = {
  'Base': getBaseScenarioState(),
  'Escenario 1': getBaseScenarioState(),
  'Escenario 2': getBaseScenarioState(),
};

export const scenariosSlice = createSlice({
  name: 'scenarios',
  initialState: initialState,
  reducers: {
    setEfficiency (state, action) {
      const { scenario, efficiency } = action.payload;
      state[scenario].efficiency = efficiency;
    },
    setEvPercentage (state, action) {
      const { scenario, evPercentage } = action.payload;
      state[scenario].vehicles.evPercentage = evPercentage;
    },
    setPrimarySource (state, action) {
      const { scenario, primarySource } = action.payload;
      state[scenario].energy.primarySource = primarySource;
    },
    setSvtEfficiency (state, action) {
      const { scenario, svtEfficiency } = action.payload;
      state[scenario].energy.svtEfficiency = svtEfficiency;
    },
    setPvEfficiency (state, action) {
      const { scenario, pvEfficiency } = action.payload;
      state[scenario].energy.pvEfficiency = pvEfficiency;
    },
    setSvtPercentage (state, action) {
      const { scenario, svtPercentage } = action.payload;
      state[scenario].energy.svtPercentage = svtPercentage;
    },
    setPvPercentage (state, action) {
      const { scenario, pvPercentage } = action.payload;
      state[scenario].energy.pvPercentage = pvPercentage;
    },
    setThermalInstFunding (state, action) {
      const { scenario, thermalInstFunding } = action.payload;
        state[scenario].funding.thermalInstalations = thermalInstFunding;
    },
    setSolarInstFunding (state, action) {
      const { scenario, solarInstFunding } = action.payload;
        state[scenario].funding.solarInstalations = solarInstFunding;
    }
  }
});

export const {
  setEfficiency,
  setEvPercentage,
  setPrimarySource,
  setSvtEfficiency,
  setPvEfficiency,
  setSvtPercentage,
  setPvPercentage,
  setThermalInstFunding,
  setSolarInstFunding
} = scenariosSlice.actions;

export const selectEnabledScenarios = createSelector(
  selectIsScenario2Enabled,
  (scen2Enabled) => {
    const scenarios = ["Base", "Escenario 1"];
    if (scen2Enabled) {
      scenarios.push("Escenario 2");
    }
    return scenarios;
  }
);

export const selectBuildingEfficiency = createSelector(
   (state, name) => state.scenarios[name],
   (scenario) => {
     return scenario.efficiency;
   }
);

export const selectVehiclesEVPercentage = createSelector(
   (state, name) => state.scenarios[name],
   (scenario) => {
     return scenario.vehicles.evPercentage;
   }
);

export const selectEnergyPrimarySource = createSelector(
   (state, name) => state.scenarios[name],
   (scenario) => {
     return scenario.energy.primarySource;
   }
);

export const selectEnergySvtEfficiency = createSelector(
   (state, name) => state.scenarios[name],
   (scenario) => {
     return scenario.energy.svtEfficiency;
   }
);

export const selectEnergyPvEfficiency = createSelector(
   (state, name) => state.scenarios[name],
   (scenario) => {
     return scenario.energy.pvEfficiency;
   }
);

export const selectEnergySvtPercentage = createSelector(
   (state, name) => state.scenarios[name],
   (scenario) => {
     return scenario.energy.svtPercentage;
   }
);

export const selectEnergyPvPercentage = createSelector(
   (state, name) => state.scenarios[name],
   (scenario) => {
     return scenario.energy.pvPercentage;
   }
);

export const selectThermalInstFunding = createSelector(
   (state, name) => state.scenarios[name],
   (scenario) => {
     return scenario.funding.thermalInstalations;
   }
);

export const selectSolarInstFunding = createSelector(
   (state, name) => state.scenarios[name],
   (scenario) => {
     return scenario.funding.solarInstalations;
   }
);

export default scenariosSlice.reducer;
