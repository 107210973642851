import { useSelector } from 'react-redux';

import Stack from '@mui/material/Stack';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

import { selectInfoEnergyBalance } from '../../../features/chartSelectors';

const EBScenarioColumn = ({ escenario }) => {
  const data = useSelector(selectInfoEnergyBalance);
  console.log("Info energ. bal", data);

  return (
    <Stack spacing={0}>
      <Typography
        gutterBottom
        variant="h6"
        component="div"
        sx={{ color: 'secondary.dark', fontWeight: 'bold' }}
      >
        {escenario}
      </Typography>
      <List sx={{ overflow: 'auto' }} dense>
        <ListItem sx={{ flexWrap: 'wrap' }}>
          <ListItemText
            primaryTypographyProps={{ fontWeight: 'bold', color: '#555' }}
            primary="Balance anual:"
          />
          <Typography variant="body" component="span">
            {(data[escenario].balance / 1000000).toFixed(2)} GWh
          </Typography>
        </ListItem>
        <ListItem sx={{ flexWrap: 'wrap' }}>
          <ListItemText
            primaryTypographyProps={{ fontWeight: 'bold', color: '#555' }}
            primary="Positividad edificio:"
          />
          <Typography variant="body" component="span">
            {data[escenario].buildingPositivity.toFixed(2)}%           
          </Typography>
        </ListItem>
        <ListItem sx={{ flexWrap: 'wrap' }}>
          <ListItemText
            primaryTypographyProps={{ fontWeight: 'bold', color: '#555' }}
            primary="Balance (veh.):"
          />
          <Typography variant="body" component="span">
            {(data[escenario].fullBalance / 1000000).toFixed(2)} GWh
          </Typography>
        </ListItem>
        <ListItem sx={{ flexWrap: 'wrap' }}>
          <ListItemText
            primaryTypographyProps={{ fontWeight: 'bold', color: '#555' }}
            primary="Positividad:"
          />
          <Typography variant="body" component="span">
           {data[escenario].positivity.toFixed(2)}%
          </Typography>
        </ListItem>
      </List>
    </Stack>
  );
};

export default EBScenarioColumn;
