import List from '@mui/material/List';
import Typography from '@mui/material/Typography';

import ItemBuildingMix from './items/ItemBuildingMix';
import ItemNeighbourhoodArea from './items/ItemNeighbourhoodArea';
import ItemPopulationDensity from './items/ItemPopulationDensity';
import ItemConsumption from './items/ItemConsumption';
import ItemNumVehicles from './items/ItemNumVehicles';
import ItemVehicleDistance from './items/ItemVehicleDistance';

import { Accordion, AccordionSummary, AccordionDetails } from './Accordions';

const CommonSettingsNeighbourhood = ({ panel, setExpandedPanel }) => {

  return (
    <>
      <Accordion
        disableGutters={true}
        sx={{ background: '#F3F0F0'}}
        expanded={panel === 'panelCommonBuilding'}
        onChange={setExpandedPanel('panelCommonBuilding')}
      >
        <AccordionSummary
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ color: 'secondary.dark', '& .MuiTypography-root': { fontWeight: 'bold' }}}
        >
          <Typography>Datos comunes - Barrio</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List sx={{ overflow: 'auto' }}>
            <ItemBuildingMix />
            <ItemNeighbourhoodArea />
            <ItemPopulationDensity />
            <ItemConsumption />
          </List>
        </AccordionDetails>
      </Accordion>
      <Accordion
        disableGutters={true}
        sx={{ background: '#F3F0F0'}}
        expanded={panel === 'panelCommonVehicles'}
        onChange={setExpandedPanel('panelCommonVehicles')}
      >
        <AccordionSummary
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ color: 'secondary.dark', '& .MuiTypography-root': { fontWeight: 'bold' }}}
        >
          <Typography>Datos comunes - Vehículos</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List sx={{ overflow: 'auto' }}>
            <ItemNumVehicles />
            <ItemVehicleDistance />
          </List>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default CommonSettingsNeighbourhood;
