import { connect, useSelector } from 'react-redux';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import {
  setEfficiency,
  selectBuildingEfficiency
} from '../../../features/scenariosSlice';


const ItemBuildingEfficiency = ({ scenario, dispatch }) => {
  const efficiency = useSelector(state => {
    return selectBuildingEfficiency(state, scenario)
  });

  const update = (value) => {
    console.log("update in itemEfficiency", value)
    dispatch(setEfficiency({ scenario: scenario, efficiency: value }));
  };

  return (
    <ListItem sx={{ flexWrap: 'wrap' }} disabled={scenario === "Base"}>
      <ListItemText
        id="switch-list-label-efficiency"
        primary="Eficiencia energética edificios:"
        sx={{ width: '200px' }}
      />
      <RadioGroup
        row
        value={efficiency}
        onChange={(v) => update(v.target.value)}
        sx={{ justifyContent: 'flex-end' }}
      >
        <FormControlLabel
          value="A"
          control={<Radio />}
          label="A"
          labelPlacement="start"
        />
        <FormControlLabel
          value="B"
          control={<Radio />}
          label="B"
          labelPlacement="start"
        />
        <FormControlLabel
          value="C"
          control={<Radio />}
          label="C"
          labelPlacement="start"
        />
        <FormControlLabel
          value="D"
          control={<Radio />}
          label="D"
          labelPlacement="start"
        />
        <FormControlLabel
          value="E"
          control={<Radio />}
          label="E"
          labelPlacement="start"
        />
        <FormControlLabel
          value="F"
          control={<Radio />}
          label="F"
          labelPlacement="start"
        />
      </RadioGroup>
    </ListItem>
  );
};

export default connect()(ItemBuildingEfficiency);
