import { connect, useSelector } from 'react-redux';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Slider from '@mui/material/Slider';

import {
  setSvtEfficiency,
  selectEnergySvtEfficiency
} from '../../../features/scenariosSlice';

const ItemSVTEfficiency = ({ scenario, dispatch }) => {
  const svtEfficiency = useSelector(state => {
    return selectEnergySvtEfficiency(state, scenario)
  });

  const update = (value) => {
    console.log("update in itemEfficiency", value)
    dispatch(setSvtEfficiency({ scenario: scenario, svtEfficiency: value }));
  };

  return (
    <ListItem sx={{ flexWrap: 'wrap' }} disabled={scenario === "Base"}>
      <ListItemText
        id="switch-list-label-svt-efficiency"
        primary="Eficencia paneles solares térmicos:"
      />
      <Slider
        value={svtEfficiency}
        onChange={(v) => update(v.target.value)}
        min={0}
        max={100}
        valueLabelDisplay="auto"
        valueLabelFormat={(value) => `${value}%`}
      />
    </ListItem>
  );
};

export default connect()(ItemSVTEfficiency);
