import List from '@mui/material/List';
import Typography from '@mui/material/Typography';

import ItemBuildingType from './items/ItemBuildingType';
import ItemArea from './items/ItemArea';
import ItemFloors from './items/ItemFloors';
import ItemDoors from './items/ItemDoors';
import ItemConsumption from './items/ItemConsumption';
import ItemNumVehicles from './items/ItemNumVehicles';
import ItemVehicleDistance from './items/ItemVehicleDistance';

import { Accordion, AccordionSummary, AccordionDetails } from './Accordions';

const CommonSettings = ({ panel, setExpandedPanel }) => {

  return (
    <>
      <Accordion
        disableGutters={true}
        sx={{ background: '#F3F0F0'}}
        expanded={panel === 'panelCommonBuilding'}
        onChange={setExpandedPanel('panelCommonBuilding')}
      >
        <AccordionSummary
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ color: 'secondary.dark', '& .MuiTypography-root': { fontWeight: 'bold' }}}
        >
          <Typography>Datos comunes - Edificio</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List sx={{ overflow: 'auto' }}>
            <ItemBuildingType />
            <ItemArea />
            <ItemFloors />
            <ItemDoors />
            <ItemConsumption />
          </List>
        </AccordionDetails>
      </Accordion>
      <Accordion
        disableGutters={true}
        sx={{ background: '#F3F0F0'}}
        expanded={panel === 'panelCommonVehicles'}
        onChange={setExpandedPanel('panelCommonVehicles')}
      >
        <AccordionSummary
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ color: 'secondary.dark', '& .MuiTypography-root': { fontWeight: 'bold' }}}
        >
          <Typography>Datos comunes - Vehículos</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List sx={{ overflow: 'auto' }}>
            <ItemNumVehicles />
            <ItemVehicleDistance />
          </List>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default CommonSettings;
