import { useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { selectEnergeticMix } from '../../../features/chartSelectors';

const MixScenarioRow = ({ escenario }) => {
  const data = useSelector(selectEnergeticMix);
  console.log("Info data", data, escenario);

  return (
    <Box sx={{ padding: 0.5 }}>
      <div style={{textAlign: 'left'}}>
        <Typography
          gutterBottom
          variant="h6"
          component="span"
          sx={{ color: 'secondary.dark', fontWeight: 'bold' }}
        >
          {escenario} 
        </Typography>
        <Typography
          variant="h6"
          component="span"
          sx={{ml: 1, color: '#777'}}
        >
          (MWh)
      </Typography>
      </div>
      <Stack spacing={2} direction="row" justifyContent="space-around">
        {data[escenario].map(info => 
            <Box key={info.name}>
              <Typography
                variant="body"
                component="span"
                style={{fontWeight: 'bold', color: '#555', margin: 10 }}
              >
                {info.abbr}
              </Typography>
              <Typography variant="body" component="span">
                {(info.value / 1000).toFixed(2)}
              </Typography>
            </Box>
        )}
      </Stack>
    </Box>
  );
};

export default MixScenarioRow;
