import {
  Legend,
  RadialBar,
  RadialBarChart,
  ResponsiveContainer
} from 'recharts';

import { useSelector } from 'react-redux';
import {
  selectAnnualElectricalBalanceFull
} from '../../features/chartSelectors';


const positiveLabelFormatter = (value) => {
  return value === 0
    ? ''
    : (value / 1000).toFixed(2) + ' GWh';
};
const negativeLabelFormatter = (value) => {
  return value === 0
    ? ''
    : '-' + (value / 1000).toFixed(2) + ' GWh';
};

const style = {
  top: '100%',
  right: 0,
  transform: 'translate(0, -50%)',
  lineHeight: '24px',
};

const ChartAnnualElectricalBalanceFull = () => {
  const data = useSelector(selectAnnualElectricalBalanceFull);
  const maxValue = Math.max(...data.map(v => Math.abs(v.balance)));
  console.log("ChartAnnualElectricalBalance", data);
  const dataPositive = data.map(d => ({
    name: d.name,
    fill: d.fill,
    balance: d.balance > 0 ? d.balance : 0,
  }));
  dataPositive.unshift({ name: '', fill: 'white', background: false, balance: maxValue });
  const dataNegative = data.map(d => ({
    name: d.name,
    fill: d.fill,
    balance: d.balance < 0 ? - d.balance : 0,
    max: maxValue+1000
  }));
  dataNegative.unshift({ name: '', fill: 'white', balance: maxValue });

  return (
    <>
    <ResponsiveContainer width="99%" height="99%">
      <RadialBarChart
        cx="50%"
        cy="100%"
        innerRadius="0%"
        outerRadius="200%"
        barSize={40}
        startAngle={180}
        endAngle={0}
        data={dataPositive}
      >
          <RadialBar
            minAngle={15}
            label={{
              position: 'insideStart',
              fill: '#fff',
              formatter: positiveLabelFormatter
            }}
            background
            clockWise={true}
            dataKey="balance"
          />
          <Legend
            iconSize={15}
            layout="vertical"
            verticalAlign="middle"
            wrapperStyle={style}
          />
        </RadialBarChart>
      </ResponsiveContainer>
      <ResponsiveContainer width="100%" height="100%">
      <RadialBarChart
        cx="50%"
        cy="10%"
        innerRadius="0%"
        outerRadius="200%"
        barSize={40}
        startAngle={180}
        endAngle={360}
        data={dataNegative}
      >
          <RadialBar
            minAngle={15}
            label={{
              position: 'insideStart',
              fill: '#fff',
              formatter: negativeLabelFormatter
            }}
            background
            clockWise={true}
            dataKey="balance"
          />
        </RadialBarChart>
      </ResponsiveContainer>
      </>
  );
}

export default ChartAnnualElectricalBalanceFull;
