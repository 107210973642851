import { useSelector } from 'react-redux';

import Grid from '@mui/material/Unstable_Grid2';

import { selectIsScenario2Enabled } from '../../../features/commonDataSlice.js';

import FinancScenarioColumn from './FinancScenarioColumn';

const InfoFinancialAnalysis = () => {
  const isScen2Enabled = useSelector(selectIsScenario2Enabled);
    
  return (
    <Grid container spacing={2}>
      <Grid xs={isScen2Enabled ? 6 : 12} >
        <FinancScenarioColumn escenario="Escenario 1" />
      </Grid>
      {isScen2Enabled &&
        <Grid xs={6} >
          <FinancScenarioColumn escenario="Escenario 2" />
        </Grid>
      }
    </Grid>
  );
};

export default InfoFinancialAnalysis;
