import { useState } from 'react'
import { connect, useSelector } from 'react-redux';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

import {
  updateConsumption,
  selectBuildingType,
  selectApartmentAnnualPowerConsumption
} from '../../../features/commonDataSlice';

import CombinationLockInput from './CombinationLockInput';

const ItemConsumption = ({ dispatch }) => {
  const buildingType = useSelector(selectBuildingType);
  const consumption = useSelector(selectApartmentAnnualPowerConsumption);
  const [isOpen, setIsOpen] = useState(false)
  const text = buildingType === 'residential' ? 'apartamento' : 'oficina';

  const update = (value) => {
    console.log("update in itemConsumption", value)
    dispatch(updateConsumption(value));
  };

  return (
    <ListItem sx={{ flexWrap: 'wrap' }}>
      <ListItemText
        id="switch-list-label-consumption"
        primary="Consumo eléctrico:"
        secondary={`anual por ${text}`}
        sx={{ minWidth: '173px' }}
      />
      <TextField
        variant="standard"
        value={consumption}
        InputProps={{
          endAdornment: <InputAdornment position="end">kWh</InputAdornment>,
        }}
        inputProps={{ style: { textAlign: 'right' }}}
        onClick={() => setIsOpen(true)}
        sx={{ width: 90, flexShrink: 0 }}
      />
      <CombinationLockInput
        num={String(consumption).padStart(3, '0')}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        update={update}
        text={`Selecciona el consumo eléctrico anual por ${text}:`}
      />
    </ListItem>
  );
};

export default connect()(ItemConsumption);
