import { useSelector } from 'react-redux';

import Stack from '@mui/material/Stack';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

import { selectTotalCO2} from '../../../features/chartSelectors';

const EmissionScenarioColumn = ({ escenario }) => {
  const data = useSelector(selectTotalCO2);
  console.log("Info emissions", data);

  return (
    <Stack spacing={2}>
      <Typography
        gutterBottom
        variant="h6"
        component="div"
        sx={{ color: 'secondary.dark', fontWeight: 'bold' }}
      >
        {escenario}
      </Typography>
      <List sx={{ overflow: 'auto' }} dense>
        <ListItem sx={{ flexWrap: 'wrap' }}>
          <ListItemText
            primaryTypographyProps={{ fontWeight: 'bold', color: '#555' }}
            primary="Emisiones totales:"
          />
          <Typography variant="body" component="span">
            {data[escenario].total.toFixed(2)} Tm
          </Typography>
        </ListItem>
        <ListItem sx={{ flexWrap: 'wrap' }}>
          <ListItemText
            primaryTypographyProps={{ fontWeight: 'bold', color: '#555' }}
            primary="CO₂ por m²:"
          />
          <Typography variant="body" component="span">
            {data[escenario].perArea.toFixed(2)} Tm
          </Typography>
        </ListItem>
        <ListItem sx={{ flexWrap: 'wrap' }}>
          <ListItemText
            primaryTypographyProps={{ fontWeight: 'bold', color: '#555' }}
            primary="CO₂ por habitante:"
          />
          <Typography variant="body" component="span">
            {data[escenario].perHab.toFixed(2)} Tm
          </Typography>
        </ListItem>
      </List>
    </Stack>
  );
};

export default EmissionScenarioColumn;
