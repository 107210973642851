import { useSelector } from 'react-redux';

import Stack from '@mui/material/Stack';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

import { selectInfoFinancialIndicators } from '../../../features/chartSelectors';

const FinancScenarioColumn = ({ escenario }) => {
  const data = useSelector(selectInfoFinancialIndicators);
  console.log("Info financ.", data);

  let time = '-';
  if (data[escenario].discountedPayback > 0) {
    time = `${data[escenario].discountedPayback.toFixed(2)} años`;
  } else if (data[escenario].discountedPayback === 0) {
    if (!isNaN(data[escenario].payback.toFixed(0))) {
      time = `>20 (~${data[escenario].payback.toFixed(0)}) años`;
    }
  }

  return (
    <Stack spacing={2}>
      <Typography
        gutterBottom
        variant="h6"
        component="div"
        sx={{ color: 'secondary.dark', fontWeight: 'bold' }}
      >
        {escenario}
      </Typography>
      <List sx={{ overflow: 'auto' }} dense>
        <ListItem sx={{ flexWrap: 'wrap' }}>
          <ListItemText
            primaryTypographyProps={{ fontWeight: 'bold', color: '#555' }}
            primary="Inversión:"
          />
          <Typography variant="body" component="span">
            {data[escenario].investment.toFixed(2)} €
          </Typography>
        </ListItem>
        <ListItem sx={{ flexWrap: 'wrap' }}>
          <ListItemText
            primaryTypographyProps={{ fontWeight: 'bold', color: '#555' }}
            primary="Costo operacional:"
          />
          <Typography variant="body" component="span">
           {data[escenario].costs.toFixed(2)} €
          </Typography>
        </ListItem>
        <ListItem sx={{ flexWrap: 'wrap' }}>
          <ListItemText
            primaryTypographyProps={{ fontWeight: 'bold', color: '#555' }}
            primary="Tiempo de retorno:"
          />
          <Typography variant="body" component="span">
           {time}
          </Typography>
        </ListItem>
        <ListItem sx={{ flexWrap: 'wrap' }}>
          <ListItemText
            primaryTypographyProps={{ fontWeight: 'bold', color: '#555' }}
            primary="Retorno de inversión:"
          />
          <Typography variant="body" component="span">
           {isNaN(data[escenario].roi)
               ? '-'
               : `${data[escenario].roi.toFixed(2)}%`
           }
          </Typography>
        </ListItem>
      </List>
    </Stack>
  );
};

export default FinancScenarioColumn;
