import { connect, useSelector } from 'react-redux';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Slider from '@mui/material/Slider';

import {
  setThermalInstFunding,
  selectThermalInstFunding
} from '../../../features/scenariosSlice';

const ItemFundingThermal = ({ scenario, dispatch }) => {
  const thermalInstFunding = useSelector(state => {
    return selectThermalInstFunding(state, scenario);
  });

  const update = (value) => {
    console.log("update in itemEfficiency", value)
    dispatch(setThermalInstFunding({ scenario: scenario, thermalInstFunding: value }));
  };

  return (
    <ListItem sx={{ flexWrap: 'wrap' }} disabled={scenario === "Base"}>
      <ListItemText
        id="switch-list-label-pv-efficiency"
        primary="Subvención de instalaciones térmicas:"
        sx={{ width: '200px' }}
      />
        <Slider
          value={thermalInstFunding}
          onChange={(v) => update(v.target.value)}
          min={0}
          max={100}
          valueLabelDisplay="auto"
          valueLabelFormat={(value) => `${value}%`}
        />
    </ListItem>
  );
};

export default connect()(ItemFundingThermal);
