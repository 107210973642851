import { useState } from 'react'
import { connect, useSelector } from 'react-redux';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';

import {
  updateFloors,
  selectBuildingFloors
} from '../../../features/commonDataSlice';

import CombinationLockInput from './CombinationLockInput';

const ItemFloors = ({ dispatch }) => {
  const floors = useSelector(selectBuildingFloors);
  const [isOpen, setIsOpen] = useState(false)

  const update = (value) => {
    console.log("update in itemFloors", value)
    dispatch(updateFloors(value));
  };

  return (
    <ListItem sx={{ flexWrap: 'wrap' }}>
      <ListItemText
        id="switch-list-label-floors"
        primary="Número de pisos:"
        sx={{ minWidth: '173px' }}
      />
      <TextField
        variant="standard"
        value={floors}
        inputProps={{ style: { textAlign: 'right' }}}
        onClick={() => setIsOpen(true)}
        sx={{ width: 90, flexShrink: 0 }}
      />
      <CombinationLockInput
        num={String(floors).padStart(3, '0')}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        update={update}
        text="Selecciona el número de pisos:"
      />
    </ListItem>
  );
};

export default connect()(ItemFloors);
