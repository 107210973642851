import { createSelector } from '@reduxjs/toolkit'
import * as config from '../config/values';
import { selectMonthlyThermalNeedsScenario } from './needsSelectors';
import { selectRoofArea } from './commonDataSlice';
import {
  selectEnergyPvPercentage,
  selectEnergyPvEfficiency,
  selectEnergySvtPercentage,
  selectEnergySvtEfficiency,
  selectEnergyPrimarySource
} from './scenariosSlice';

// PVT Generation

export const selectMonthlyPVTGenerationScenario = createSelector(
  selectEnergyPrimarySource,
  selectRoofArea,
  selectEnergySvtPercentage,
  selectEnergySvtEfficiency,
  (primarySource, area, svtPerc, svtEff) => {
    return config.days.map((days, index) => {
      return area * svtPerc/100 * svtEff/100 * config.RADH[index] * days;
    });
  }
);

export const selectPVTGenerationInMonthScenario = createSelector(
  (state, month_index) => month_index,
  selectMonthlyPVTGenerationScenario,
  (month, monthlyGeneration) => {
    return monthlyGeneration[month];
  }
);

export const selectAnnualPVTGenerationScenario = createSelector(
  selectMonthlyPVTGenerationScenario,
  (monthlyGeneration) => {
    return monthlyGeneration.reduce((acc, month_generation) => {
      return acc + month_generation;
    }, 0);
  }
);

// Geothermal Generation

export const selectMonthlyGeothermalGenerationScenario = createSelector(
  selectEnergyPrimarySource,
  selectMonthlyPVTGenerationScenario,
  selectMonthlyThermalNeedsScenario,
  (primarySource, pvtGeneration, monthlyNeeds) => {
    if (primarySource === 'geotermia') {
      return monthlyNeeds.map((val, index) => {
        return val - pvtGeneration[index];
      });
    }
    return Array(12).fill(0);
  }
);

export const selectGeothermalGenerationInMonthScenario = createSelector(
  (state, month_index) => month_index,
  selectMonthlyGeothermalGenerationScenario,
  (month, monthlyGeneration) => {
    return monthlyGeneration[month];
  }
);

export const selectAnnualGeothermalGenerationScenario = createSelector(
  selectMonthlyGeothermalGenerationScenario,
  (monthlyGeneration) => {
    return monthlyGeneration.reduce((acc, month_generation) => {
      return acc + month_generation;
    }, 0);
  }
);

// Aerothermal Generation

export const selectMonthlyAerothermalGenerationScenario = createSelector(
  selectEnergyPrimarySource,
  selectMonthlyPVTGenerationScenario,
  selectMonthlyThermalNeedsScenario,
  (primarySource, pvtGeneration, monthlyNeeds) => {
    if (primarySource === 'aerotermia') {
      return monthlyNeeds.map((val, index) => {
        return val - pvtGeneration[index];
      });
    }
    return Array(12).fill(0);
  }
);

export const selectAerothermalGenerationInMonthScenario = createSelector(
  (state, month_index) => month_index,
  selectMonthlyAerothermalGenerationScenario,
  (month, monthlyGeneration) => {
    return monthlyGeneration[month];
  }
);

export const selectAnnualAerothermalGenerationScenario = createSelector(
  selectMonthlyGeothermalGenerationScenario,
  (monthlyGeneration) => {
    return monthlyGeneration.reduce((acc, month_generation) => {
      return acc + month_generation;
    }, 0);
  }
);

// Gas Natural Generation

export const selectMonthlyGasNaturalGenerationScenario = createSelector(
  selectEnergyPrimarySource,
  selectMonthlyPVTGenerationScenario,
  selectMonthlyThermalNeedsScenario,
  (primarySource, pvtGeneration, monthlyNeeds) => {
    if (primarySource === 'gasNatural') {
      return monthlyNeeds.map((val, index) => {
        return val - pvtGeneration[index];
      });
    }
    return Array(12).fill(0);
  }
);

export const selectGasNaturalGenerationInMonthScenario = createSelector(
  (state, month_index) => month_index,
  selectMonthlyGasNaturalGenerationScenario,
  (month, monthlyGeneration) => {
    return monthlyGeneration[month];
  }
);

export const selectAnnualGasNaturalGenerationScenario = createSelector(
  selectMonthlyGasNaturalGenerationScenario,
  (monthlyGeneration) => {
    return monthlyGeneration.reduce((acc, month_generation) => {
      return acc + month_generation;
    }, 0);
  }
);

// Full Thermal Generation

export const selectMonthlyThermalGenerationScenario = createSelector(
  selectMonthlyGeothermalGenerationScenario,
  selectMonthlyAerothermalGenerationScenario,
  selectMonthlyPVTGenerationScenario,
  selectMonthlyGasNaturalGenerationScenario,
  (geo, aero, pvt, gas) => {
    return geo.map((val, index) => {
      return val + aero[index] + pvt[index] + gas[index];
    });
  }
);

export const selectThermalGenerationInMonthScenario = createSelector(
  (state, month_index) => month_index,
  selectMonthlyThermalGenerationScenario,
  (month, monthlyGeneration) => {
    return monthlyGeneration[month];
  }
);

export const selectAnnualThermalGenerationScenario = createSelector(
  selectMonthlyThermalGenerationScenario,
  (monthlyGeneration) => {
    return monthlyGeneration.reduce((acc, month_generation) => {
      return acc + month_generation;
    }, 0);
  }
);

// PV Generation
export const selectMonthlyPVGenerationScenario = createSelector(
  selectRoofArea,
  selectEnergyPvPercentage,
  selectEnergyPvEfficiency,
  (area, pvPerc, pvEff) => {
    return config.irradiance.map((irradiance, index) => {
      return area * pvPerc/100 * pvEff/100 * irradiance;
    });
  }
);

export const selectPVGenerationInMonthScenario = createSelector(
  (state, month_index) => month_index,
  selectMonthlyPVGenerationScenario,
  (month, monthlyGeneration) => {
    return monthlyGeneration[month];
  }
);

export const selectAnnualPVGenerationScenario = createSelector(
  selectMonthlyPVGenerationScenario,
  (monthlyGeneration) => {
    return monthlyGeneration.reduce((acc, month_generation) => {
      return acc + month_generation;
    }, 0);
  }
);

// Full Generation

export const selectMonthlyGenerationScenario = createSelector(
  selectMonthlyThermalGenerationScenario,
  selectMonthlyPVGenerationScenario,
  (thermal, electric) => {
    console.log('BAL: +GENERATION', 'thermal', thermal, 'electrical', electric);
    return thermal.map((thermal_gen, index) => {
      return thermal_gen + electric[index];
    });
  }
);

export const selectGenerationInMonthScenario = createSelector(
  (state, month_index) => month_index,
  selectMonthlyGenerationScenario,
  (month, monthlyGeneration) => {
    return monthlyGeneration[month];
  }
);

export const selectAnnualGenerationScenario = createSelector(
  selectMonthlyGenerationScenario,
  (monthlyGeneration) => {
    return monthlyGeneration.reduce((acc, month_generation) => {
      return acc + month_generation;
    }, 0);
  }
);

export const selectMonthlyGeneration = createSelector(
  state => selectMonthlyGenerationScenario(state, 'Base'),
  state => selectMonthlyGenerationScenario(state, 'Escenario 1'),
  state => selectMonthlyGenerationScenario(state, 'Escenario 2'),
  (base, scenario1, scenario2) => {
    return {
      Base: base,
      'Escenario 1': scenario1,
      'Escenario 2': scenario2 
    };
  }
);

export const selectAnnualGeneration = createSelector(
  state => selectAnnualGenerationScenario(state, "Base"),
  state => selectAnnualGenerationScenario(state, "Escenario 1"),
  state => selectAnnualGenerationScenario(state, "Escenario 2"),
  (base, scenario1, scenario2) => {
    return {
      Base: base,
      'Escenario 1': scenario1,
      'Escenario 2': scenario2 
    };
  }
);

// Full Generation (Primary Energy)

export const selectMonthlyGenerationPrimaryEnergy = createSelector(
  state => selectMonthlyGenerationPrimaryEnergyScenario(state, 'Base'),
  state => selectMonthlyGenerationPrimaryEnergyScenario(state, 'Escenario 1'),
  state => selectMonthlyGenerationPrimaryEnergyScenario(state, 'Escenario 2'),
  (base, scenario1, scenario2) => {
    return {
      Base: base,
      'Escenario 1': scenario1,
      'Escenario 2': scenario2 
    };
  }
);

export const selectMonthlyGenerationPrimaryEnergyScenario = createSelector(
  selectMonthlyThermalGenerationScenario,
  selectMonthlyPVGenerationScenario,
  (thermal, electric) => {
    console.log('BAL: +GENERATION', 'thermal', thermal, 'electrical', electric);
    return thermal.map((thermal_gen, index) => {
      return thermal_gen + electric[index] * config.conversionFactors.elecToEnergy;
    });
  }
);

// Electrical Generation

export const selectMonthlyElectricalGenerationScenario = createSelector(
  selectMonthlyPVGenerationScenario,
  (electric) => {
    return electric;
  }
);

export const selectAnnualElectricalGenerationScenario = createSelector(
  selectMonthlyElectricalGenerationScenario,
  (monthlyGeneration) => {
    return monthlyGeneration.reduce((acc, month_generation) => {
      return acc + month_generation;
    }, 0);
  }
);

export const selectMonthlyElectricalGeneration = createSelector(
  state => selectMonthlyElectricalGenerationScenario(state, 'Base'),
  state => selectMonthlyElectricalGenerationScenario(state, 'Escenario 1'),
  state => selectMonthlyElectricalGenerationScenario(state, 'Escenario 2'),
  (base, scenario1, scenario2) => {
    return {
      Base: base,
      'Escenario 1': scenario1,
      'Escenario 2': scenario2 
    };
  }
);

export const selectAnnualElectricalGeneration = createSelector(
  state => selectAnnualElectricalGenerationScenario(state, "Base"),
  state => selectAnnualElectricalGenerationScenario(state, "Escenario 1"),
  state => selectAnnualElectricalGenerationScenario(state, "Escenario 2"),
  (base, scenario1, scenario2) => {
    return {
      Base: base,
      'Escenario 1': scenario1,
      'Escenario 2': scenario2 
    };
  }
);
