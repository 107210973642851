export const heating = {
  residential: {
    'A': 8.7,
    'B': 14.46,
    'C': 20.23,
    'D': 28.47,
    'E': 36.70,
    'F': 49.05,
    'G': 61.41
  },
  terciary: {
    'A': 16.80,
    'B': 21.00,
    'C': 25.20,
    'D': 31.00,
    'E': 36.80,
    'F': 41.60,
    'G': 46.40
  }
};

export const dhw = {
  residential: {
    'A': 8.88,
    'B': 9.64,
    'C': 10.40,
    'D': 11.48,
    'E': 12.56,
    'F': 14.19,
    'G': 15.81
  },
  terciary: {
    'A': 9.96,
    'B': 10.51,
    'C': 11.05,
    'D': 11.80,
    'E': 12.56,
    'F': 13.21,
    'G': 13.86
  }
};

export const cooling = {
  residential: {
    'A': 0,
    'B': 0,
    'C': 0,
    'D': 0,
    'E': 0,
    'F': 0,
    'G': 0
  },
  terciary: {
    'A': 8.4,
    'B': 10.5,
    'C': 12.6,
    'D': 15.5,
    'E': 18.4,
    'F': 20.8,
    'G': 23.2 
  }
}

export const heatingDegreeDays = [
  183,
  168,
  126,
  94,
  42,
  10,
  3,
  2,
  11,
  33,
  117,
  181
];
export const coolingDegreeDays = [  // TODO rellenar
  0,
  0,
  0,
  0,
  18,
  37,
  47,
  58,
  37,
  22,
  0,
  0
];

export const totalHeatingDegreeDays = heatingDegreeDays.reduce(
  (acc, d) => acc + d, 0
);

export const totalCoolingDegreeDays = coolingDegreeDays.reduce(
  (acc, d) => acc + d, 0
);

export const thermicalFactorHDD = heatingDegreeDays.map(hdd => {
  return hdd / totalHeatingDegreeDays
});

export const thermicalFactorCDD = coolingDegreeDays.map(cdd => {
  return cdd / totalCoolingDegreeDays
});

export const months = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre'
];

export const days_per_month = 30;
export const days = [
  31,
  28,
  31,
  30,
  31,
  30,
  31,
  31,
  30,
  31,
  30,
  31
];
export const RADH = [
  1.4,
  2,
  3.2,
  4.1,
  5.1,
  5.5,
  5.3,
  4.8,
  3.8,
  2.5,
  1.6,
  1.1
];
export const irradiance = [
  93.18,
  115.48,
  130.79,
  133.07,
  177.41,
  145.78,
  174.01,
  156.42,
  159.21,
  91.82,
  108.33,
  59.94
];

// conversion factor from liters of gasoline to energy
export const FCgasToEnergy = 8.89;
export const conversionFactors = {
  electricPerKm: 0.18,
  ltsGasToEnergy: 8.89,
  gasPerKm: 0.07,
  elecToEnergy: 2.61,
  gasNaturalToEnergy: 1.01
}

export const COPs = {
  "geotermia": 5,
  "aerotermia": 4,
  "gasNatural": 1
};

export const CoPs = {
  "geotermia": {
    "Heating": 5,
    "Cooling": 5,
    "DHW": 5
  },
  "aerotermia": {
    "Heating": 4,
    "Cooling": 4,
    "DHW": 4
  },
  "gasNatural": {
    "Heating": 1,
    "Cooling": 1,
    "DHW": 1
  }
};

export const emissionFactor = {
  "geotermia": 0.34,
  "aerotermia": 0.34,
  "electricity": 0.34,
  "gasNatural": 0.22, // kgCO2/Kwh
  "gasoline": 14.95,
  "ve": 2.53
};

// https://solarthermalworld.org/news/solar-thermal-shows-highest-energy-yield-square-metre/
export const svtPower = 150; // kWh/m2 

export const financial = {
  panelArea: 2.65, // m²
  panelPrice: 147, // €
  controlCarga: 1800, // €
  maintenance: 0.005, // 0.5 %
  oic: 0.2, // 20%
  geothermy: 350, // 159, // €/m²
  aerothermy: 162, // €/m²
  gasNatural: 0.0841, // €/kWh
  electricity: 0.1823, // €/kWh
  inflation: 1.02, // 2%
  interest: 1.02, // 2%
  years: 20, // years
}

export const density = {
  apartment: 3, // people
  office: 20, // people
}
