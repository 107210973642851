import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';

import InfoPanel from './InfoPanel';
import Charts from './Charts';
import Settings from './Settings';

const MainLayout = () => {

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <Stack spacing={2} direction="row" sx={{ padding: 2, height: '100%' }} >
        <Settings sx={{ maxWidth: '456px' }} />
        <Stack spacing={2} sx={{ width: '100%' }} >
          <Charts />
          <Card
            variant="outlined"
            sx={{
              borderColor: "primary.main",
              borderWidth: "medium",
              borderRadius: 2,
              height: '51.5%'
            }}
          >
            <InfoPanel background="card.background" />
          </Card>
        </Stack>
      </Stack>
    </Box>
  );
};

export default MainLayout;
