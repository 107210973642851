import { useSelector } from 'react-redux';
import './App.css';

import Stack from '@mui/material/Stack';

import MainBar from './components/MainBar';
import MainLayout from './components/MainLayout';
import ChooseScreen from './components/ChooseScreen';
import BottomBar from './components/BottomBar';

import { selectSimulationType } from './features/commonDataSlice';

function App() {
  const simulationType = useSelector(selectSimulationType);

  return (
    <Stack
      className="App"
      direction="column"
      style={{ height: '100vh'}}
    >
      <MainBar />
      { simulationType === '-' ? <ChooseScreen /> : <MainLayout /> }
      <BottomBar />
    </Stack>
  );
}

export default App;
