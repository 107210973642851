import { connect, useSelector } from 'react-redux';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';

import {
  setBuildingType,
  selectBuildingType
} from '../../../features/commonDataSlice';


const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(6px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(22px)',
      '& .MuiSwitch-thumb:before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          '#fff',
        )}" d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z"/></svg>')`,
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#a8d6eb',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: theme.palette.mode === 'dark' ?  '#003892' : '#2DAAE2',
    width: 32,
    height: 32,
    '&::before': {
      content: "''",
      position: 'absolute',
      width: '95%',
      height: '85%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        '#fff',
      )}" d="M22 10v12H2V10l7-3v2l5-2v3zm-4.8-1.5L18 2h3l.8 6.5zM11 18h2v-4h-2zm-4 0h2v-4H7zm10-4h-2v4h2z"/></svg>')`,
    },
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#a8d6eb',
    borderRadius: 20 / 2,
  },
}));


const ItemBuildingType = ({ dispatch }) => {
  const buildingType = useSelector(selectBuildingType);

  const update = (event) => {
    const value = event.target.checked ? 'residential': 'terciary';
    console.log("update in itemBuildingType", value)
    dispatch(setBuildingType(value));
  };

  return (
    <ListItem sx={{ flexWrap: 'wrap' }}>
      <ListItemText
        id="switch-list-label-building-type"
        primary={`Tipo de edificio: ${buildingType === 'residential' ?  "residencial" : "terciario"}`}
        sx={{ minWidth: '173px' }}
      />
      <FormGroup>
      <FormControlLabel
        control={
          <MaterialUISwitch
            sx={{ m: 1 }}
            defaultChecked
            onChange={update}
          />
        }
        label={/*  */ " "}
        labelPlacement="start"
      />
      </FormGroup>
    </ListItem>
  );
};

export default connect()(ItemBuildingType);
