import { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import { setScenario2Enable } from '../../../features/commonDataSlice';
import { selectEnabledScenarios } from '../../../features/scenariosSlice';

import Scenario from './Scenario';
import AddScenarioFab from './AddScenarioFab';

const ScenariosTabs = ({ dispatch, panel, setExpandedPanel }) => {
  const [tab, setTab] = useState(0);
  const scenarios = useSelector(selectEnabledScenarios);

  useEffect(() => {
    setTab(scenarios.length - 1);
  }, [scenarios, setTab]);

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <div style={{ position: 'relative', background: 'white' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <Tabs
        value={tab}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
      >
        {scenarios.map((scenario, index) => (
          <Tab
            key={index}
            label={scenario}
            icon={ index > 1 &&
              <IconButton
                onClick={() => dispatch(setScenario2Enable(false))}
                size="small"
                sx={{ height: 16, width: 16, ' & svg': { fontSize: 14}}}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            iconPosition="end"
            sx={{
              minHeight: '48px',
              fontWeight: 'bold',
              fontSize: '1rem',
              color: '#C4C4C4',
              textTransform: 'none'
            }}
          />
        ))}
      </Tabs>
      </Box>
      <div style={{ /* marginBottom: 70 */ }}>
        {scenarios.map((scenario, index) => (
          tab === index && (
            <div key={index}>
              <Scenario
                name={scenario}
                panel={panel}
                setExpandedPanel={setExpandedPanel}
              />
            </div>
          )
        ))}
      </div>
      <AddScenarioFab />
    </div>
  );
}

export default connect()(ScenariosTabs);
