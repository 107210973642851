import { useSelector } from 'react-redux';

import Stack from '@mui/material/Stack';

import { selectIsScenario2Enabled } from '../../../features/commonDataSlice.js';

import MixScenarioRow from './MixScenarioRow';

const InfoEnergyMix = () => {
  const isScen2Enabled = useSelector(selectIsScenario2Enabled);
    
  return (
    <Stack spacing={1} justifyContent="space-evenly">
      <MixScenarioRow escenario="Base" />
      <MixScenarioRow escenario="Escenario 1" />
      {isScen2Enabled &&
          <MixScenarioRow escenario="Escenario 2" />
      }
    </Stack>
  );
};

export default InfoEnergyMix;
