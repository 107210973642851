import { useState } from 'react'
import { connect, useSelector } from 'react-redux';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

import {
  updateDailyDistance,
  selectVehiclesDailyDistance
} from '../../../features/commonDataSlice';

import CombinationLockInput from './CombinationLockInput';

const ItemVehicleDistance = ({ dispatch }) => {
  const distance = useSelector(state => {
    return selectVehiclesDailyDistance(state)
  });
  const [isOpen, setIsOpen] = useState(false)

  const update = (value) => {
    console.log("update in itemEfficiency", value)
    dispatch(updateDailyDistance(value));
  };

  return (
    <ListItem sx={{ flexWrap: 'wrap' }}>
      <ListItemText
        id="switch-list-label-distance"
        primary="Distancia diaria por vehículo:"
      />
      <TextField
        variant="standard"
        value={distance}
        inputProps={{ style: { textAlign: 'right' }}}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">km</InputAdornment>
          ),
        }}
        onClick={() => setIsOpen(true)}
        sx={{ width: 90, flexShrink: 0 }}
      />
      <CombinationLockInput
        num={String(distance).padStart(3, '0')}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        update={update}
        text="Selecciona la distancia media que recorre un vehículo cada día:"
      />
    </ListItem>
  );
};

export default connect()(ItemVehicleDistance);
