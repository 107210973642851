import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ComposedChart,
  ResponsiveContainer
} from 'recharts';

import { useSelector } from 'react-redux';
import { selectIsScenario2Enabled } from '../../features/commonDataSlice.js';
import { selectInvestmentResults } from '../../features/chartSelectors';

const ChartFinancialAnalysis = () => {
  const data = useSelector(selectInvestmentResults);
  console.log("Financial Analysis", data);
  const isScen2Enabled = useSelector(selectIsScenario2Enabled);
  const formatTooltip = (value) => {
    return value.toFixed(2);
  };

  return (
    <ResponsiveContainer width="99%" height="99%">
      <ComposedChart
        width={730}
        height={250}
        data={data}
        margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
      >
        <XAxis dataKey="name" angle={-60} textAnchor="end" height={50}/>
        <YAxis angle={0} textAnchor="end" width={70}/>
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip formatter={formatTooltip} />
        <Bar dataKey="Escenario 1" stroke="#8884d8" fillOpacity={1} fill="#2DAAE2"/>
        { isScen2Enabled && 
            <Bar dataKey="Escenario 2" stroke="#82ca9d" fillOpacity={1} fill="#DCDC00" />
        }
      </ComposedChart>
    </ResponsiveContainer>
  );
}

export default ChartFinancialAnalysis;
