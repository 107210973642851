import {
  Legend,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ReferenceLine,
  ResponsiveContainer,
} from 'recharts';

import { useSelector } from 'react-redux';
import { selectAnnualCO2} from '../../features/chartSelectors';

const ChartAnnualEmissions = () => {
  const data = useSelector(selectAnnualCO2);
  const formatTooltip = (value) => {
    console.log("ChartAnnualEmissions - value", value);
    return value.toFixed(2);
  };

  return (
    <ResponsiveContainer width="99%" height="99%">
      <BarChart
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
        stackOffset='sign'
      >
        <CartesianGrid strokeDasharray="3 3" />
        <ReferenceLine stroke="#C4C4C4" strokeWidth={2} y={0} />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip formatter={formatTooltip} />
        <Legend />
        <Bar
          dataKey="PV"
          stackId="a"
          fill="#3aaa35"
          maxBarSize={170}
          unit=" tm CO₂"
        />
        <Bar
          dataKey="Thermal"
          stackId="a"
          fill="#931B80"
          maxBarSize={170}
          unit=" tm CO₂"
        />
        <Bar
          dataKey="Transportation"
          stackId="a"
          fill="#2DAAE2"
          maxBarSize={170}
          unit=" tm CO₂"
        />
        <Bar
          dataKey="Appliances"
          stackId="a"
          fill="#DCDC00"
          maxBarSize={170}
          unit=" tm CO₂"
        />
      </BarChart>
    </ResponsiveContainer>
  );
}

export default ChartAnnualEmissions;
