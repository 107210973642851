import { createSelector } from '@reduxjs/toolkit'
import * as config from '../config/values';
import {
  selectRoofArea,
  selectBuildingTotalArea
} from './commonDataSlice';
import {
  selectSolarInstFunding,
  selectThermalInstFunding,
  selectEnergyPvPercentage,
  selectEnergySvtPercentage,
  selectEnergyPrimarySource 
} from './scenariosSlice';
import {
  selectAnnualPVGenerationScenario,
  selectMonthlyThermalGenerationScenario,
} from './generationsSelectors';
import {
  selectAnnualHPConsumpScenario
} from './consumptionsSelectors';


// PV Inversion

export const selectNumPanelsScenario = createSelector(
  selectRoofArea,
  selectEnergyPvPercentage,
  selectEnergySvtPercentage,
  (area, pvPerc, svtPerc) => {
    return area * (svtPerc + pvPerc) / 100 / config.financial.panelArea;
  }
);

export const selectPanelsInvestmentScenario = createSelector(
  selectNumPanelsScenario,
  (num_panels) => {
    return num_panels * config.financial.panelPrice;
  }
);

export const selectPanelsOICScenario = createSelector(
  selectPanelsInvestmentScenario,
  (inversion) => {
    return inversion * config.financial.oic; 
  }
);

export const selectPanelsMaintenanceScenario = createSelector(
  selectPanelsInvestmentScenario,
  (investment) => {
    const m = investment * config.financial.maintenance; 
    const maintenance = [];
    for (let i = 0; i < config.financial.years; i++) {
      if (i === 0) {
        maintenance.push(m);
      } else {
        maintenance.push(maintenance[i - 1] * config.financial.inflation);
      }
    }
    return maintenance;
  }
);

export const selectFinalPanelsInvestmentScenario = createSelector(
  selectSolarInstFunding,
  selectPanelsInvestmentScenario,
  selectPanelsOICScenario,
  (fundingPerc, investment, oic) => {
    return investment - investment * fundingPerc/100 + oic;
  }
);

// Geothermal Inversion

export const selectGeothermalInversionScenario = createSelector(
  selectEnergyPrimarySource,
  selectBuildingTotalArea,
  selectThermalInstFunding,
  (primarySource, area, fundingPerc) => {
    if (primarySource === 'geotermia') {
      const investment = area * config.financial.geothermy;
      return investment - investment * fundingPerc/100;
    }
    return 0;
  }
);

// Aerothermal Inversion

export const selectAerothermalInversionScenario = createSelector(
  selectEnergyPrimarySource,
  selectBuildingTotalArea,
  selectThermalInstFunding,
  (primarySource, area, fundingPerc) => {
    if (primarySource === 'aerotermia') {
      const investment = area * config.financial.aerothermy;
      return investment - investment * fundingPerc/100;
    }
    return 0;
  }
);

// Saves Gas Natural

export const selectSavesGasNaturalScenario = createSelector(
  selectMonthlyThermalGenerationScenario,
  (generation) => {
    const kWhSaved = generation.reduce((acc, month) => {
      return acc + month;
    }, 0);
    const moneySaved = kWhSaved * config.financial.gasNatural;
    const saves = [];
    for (let i = 0; i < config.financial.years; i++) {
      if (i === 0) {
        saves.push(moneySaved);
      } else {
        saves.push(saves[i - 1] * config.financial.inflation);
      }
    }
    return saves;
  }
);

// Saves Electricity
export const selectSavesElectricityScenario = createSelector(
  selectAnnualPVGenerationScenario,
  (state, scenario) =>  selectAnnualHPConsumpScenario(state, scenario, 'geotermia'),
  (state, scenario) =>  selectAnnualHPConsumpScenario(state, scenario, 'aerotermia'),
  (pv, geo, aero) => {
    const moneySaved = (pv - geo - aero) * config.financial.electricity;
    const saves = [];
    for (let i = 0; i < config.financial.years; i++) {
      if (i === 0) {
        saves.push(moneySaved);
      } else {
        saves.push(saves[i - 1] * config.financial.inflation);
      }
    }
    return saves;
  }
);

// Financial

export const selectFinancialInversionScenario = createSelector(
  selectFinalPanelsInvestmentScenario,
  selectGeothermalInversionScenario,
  selectAerothermalInversionScenario,
  selectPanelsMaintenanceScenario,
  selectSavesGasNaturalScenario,
  selectSavesElectricityScenario,
  (panelInvest, geoInvest, aeroInvest, maint, gasSaved, elecSaved) => {
    console.log("---");
    console.log('invest pv', panelInvest);
    console.log('invest geo', geoInvest);
    console.log('invest aero', aeroInvest);
    console.log('maint', maint);
    console.log('gas saved', gasSaved);
    console.log('elec saved', elecSaved);
    const result = [];
    for (let i=0; i < config.financial.years; i++) {
      if (i === 0) {
        const val = gasSaved[i]
          + elecSaved[i]
          - panelInvest
          - geoInvest
          - aeroInvest
          - maint[i];
        result.push(val);
      } else {
        const val = result[i - 1] + gasSaved[i] + elecSaved[i] - maint[i];
        result.push(val);
      }
    }
    return result;
  }
);

export const selectFinancialInversion = createSelector(
  state => selectFinancialInversionScenario(state, "Base"),
  state => selectFinancialInversionScenario(state, "Escenario 1"),
  state => selectFinancialInversionScenario(state, "Escenario 2"),
  (base, scenario1, scenario2) => {
    console.log("Financial escenario 1", scenario1);
    return {
      Base: base,
      'Escenario 1': scenario1,
      'Escenario 2': scenario2 
    };
  }
);

export const selectFinancialIndicatorsScenario = createSelector(
  selectFinalPanelsInvestmentScenario,
  selectGeothermalInversionScenario,
  selectAerothermalInversionScenario,
  selectPanelsMaintenanceScenario,
  selectSavesGasNaturalScenario,
  selectSavesElectricityScenario,
  selectFinancialInversionScenario,
  (panelInvest, geoInvest, aeroInvest, maint, gasSaved, elecSaved, years) => {
    const investment = panelInvest + geoInvest + aeroInvest;
    const operationalCosts = maint;
    const cashFlows = gasSaved[0] + elecSaved[0];

    return {
      investment: investment,
      costs: operationalCosts[0],
      cashFlows: cashFlows,
      time: years.findIndex(v => v > 0) + 1
    };
  }
);

export const selectFinancialIndicators = createSelector(
  state => selectFinancialIndicatorsScenario(state, "Escenario 1"),
  state => selectFinancialIndicatorsScenario(state, "Escenario 2"),
  (scenario1, scenario2) => {
    return {
      'Escenario 1': scenario1,
      'Escenario 2': scenario2 
    };
  }
);
