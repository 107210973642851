import { createSelector } from '@reduxjs/toolkit'
import * as config from '../config/values';
import {
  selectBuildingType,
  selectBuildingTotalArea
} from './commonDataSlice';
import { selectBuildingEfficiency } from './scenariosSlice';

// Heating needs

export const selectMonthlyHeatingNeedsScenario = createSelector(
  selectBuildingType,
  selectBuildingTotalArea,
  (state, scenario) => selectBuildingEfficiency(state, scenario),
  (buildingType, totalArea, efficiency) => {
    return config.thermicalFactorHDD.map(hdd => {
      return config.heating[buildingType][efficiency] * totalArea * hdd;
    });
  }
);

export const selectHeatingNeedsInMonthScenario = createSelector(
  (state, scenario, month_index) => month_index,
  selectMonthlyHeatingNeedsScenario,
  (month, monthlyNeeds) => {
    return monthlyNeeds[month];
  }
);

export const selectAnnualHeatingNeedsScenario = createSelector(
  selectMonthlyHeatingNeedsScenario,
  (monthlyNeeds) => {
    return monthlyNeeds.reduce((acc, month_needs) => acc + month_needs, 0);
  }
);

export const selectMonthlyHeatingNeeds = createSelector (
  state => selectMonthlyHeatingNeedsScenario(state, 'Base'),
  state => selectMonthlyHeatingNeedsScenario(state, 'Escenario 1'),
  state => selectMonthlyHeatingNeedsScenario(state, 'Escenario 2'),
  (consumptionBase, consumption1, consumption2) => {
    return {
      Base: consumptionBase,
      'Escenario 1': consumption1,
      'Escenario 2': consumption2 
    };
  }
);

export const selectAnnualHeatingNeeds = createSelector(
  state => selectAnnualHeatingNeedsScenario(state, "Base"),
  state => selectAnnualHeatingNeedsScenario(state, "Escenario 1"),
  state => selectAnnualHeatingNeedsScenario(state, "Escenario 2"),
  (annualNeedsBase, annualNeedsGen1, annualNeedsGen2) => {
    return {
      Base: annualNeedsBase,
      'Escenario 1': annualNeedsGen1,
      'Escenario 2': annualNeedsGen2
    };
  }
);

// Cooling needs

export const selectMonthlyCoolingNeedsScenario = createSelector(
  selectBuildingType,
  selectBuildingTotalArea,
  (state, scenario) => selectBuildingEfficiency(state, scenario),
  (buildingType, totalArea, efficiency) => {
    return config.thermicalFactorCDD.map(cdd => {
      return config.cooling[buildingType][efficiency] * totalArea * cdd;
    });
  }
);

export const selectCoolingNeedsInMonthScenario = createSelector(
  (state, month_index) => month_index,
  selectMonthlyCoolingNeedsScenario,
  (month, monthlyNeeds) => {
    return monthlyNeeds[month];
  }
);

export const selectAnnualCoolingNeedsScenario = createSelector(
  selectMonthlyCoolingNeedsScenario,
  (monthlyNeeds) => {
    return monthlyNeeds.reduce((acc, month_needs) => acc + month_needs, 0);
  }
);

export const selectMonthlyCoolingNeeds = createSelector (
  state => selectMonthlyCoolingNeedsScenario(state, 'Base'),
  state => selectMonthlyCoolingNeedsScenario(state, 'Escenario 1'),
  state => selectMonthlyCoolingNeedsScenario(state, 'Escenario 2'),
  (consumptionBase, consumption1, consumption2) => {
    return {
      Base: consumptionBase,
      'Escenario 1': consumption1,
      'Escenario 2': consumption2 
    };
  }
);

export const selectAnnualCoolingNeeds = createSelector(
  state => selectAnnualCoolingNeedsScenario(state, "Base"),
  state => selectAnnualCoolingNeedsScenario(state, "Escenario 1"),
  state => selectAnnualCoolingNeedsScenario(state, "Escenario 2"),
  (annualNeedsBase, annualNeedsGen1, annualNeedsGen2) => {
    return {
      Base: annualNeedsBase,
      'Escenario 1': annualNeedsGen1,
      'Escenario 2': annualNeedsGen2
    };
  }
);

// DHW needs

export const selectMonthlyDHWNeedsScenario = createSelector(
  selectBuildingType,
  selectBuildingTotalArea,
  (state, scenario) => selectBuildingEfficiency(state, scenario),
  (buildingType, totalArea, efficiency) => {
    return config.months.map(m => {
      return config.dhw[buildingType][efficiency] * totalArea;
    });
  }
);

export const selectDHWNeedsInMonthScenario = createSelector(
  (state, month_index) => month_index,
  selectMonthlyDHWNeedsScenario,
  (month, monthlyNeeds) => {
    return monthlyNeeds[month];
  }
);

export const selectAnnualDHWNeedsScenario = createSelector(
  selectMonthlyDHWNeedsScenario,
  (monthlyNeeds) => {
    return monthlyNeeds.reduce((acc, month_needs) => acc + month_needs, 0);
  }
);

export const selectMonthlyDHWNeeds = createSelector (
  state => selectMonthlyDHWNeedsScenario(state, 'Base'),
  state => selectMonthlyDHWNeedsScenario(state, 'Escenario 1'),
  state => selectMonthlyDHWNeedsScenario(state, 'Escenario 2'),
  (consumptionBase, consumption1, consumption2) => {
    return {
      Base: consumptionBase,
      'Escenario 1': consumption1,
      'Escenario 2': consumption2 
    };
  }
);

export const selectAnnualDHWNeeds = createSelector(
  state => selectAnnualDHWNeedsScenario(state, "Base"),
  state => selectAnnualDHWNeedsScenario(state, "Escenario 1"),
  state => selectAnnualDHWNeedsScenario(state, "Escenario 2"),
  (annualNeedsBase, annualNeedsGen1, annualNeedsGen2) => {
    return {
      Base: annualNeedsBase,
      'Escenario 1': annualNeedsGen1,
      'Escenario 2': annualNeedsGen2
    };
  }
);

// Full Thermal needs (heating + cooling + dhw)

export const selectMonthlyThermalNeedsScenario = createSelector(
  selectMonthlyHeatingNeedsScenario,
  selectMonthlyCoolingNeedsScenario,
  selectMonthlyDHWNeedsScenario,
  (heating, cooling, dhw) => {
    return heating.map((val, index) => {
      return val + cooling[index] + dhw[index];
    });
  }
);

export const selectThermalNeedsInMonthScenario = createSelector(
  (state, month_index) => month_index,
  selectMonthlyThermalNeedsScenario,
  (month, monthlyNeeds) => {
    return monthlyNeeds[month];
  }
);

export const selectThermalAnnualNeedsScenario = createSelector(
  selectMonthlyThermalNeedsScenario,
  (monthlyNeeds) => {
    return monthlyNeeds.reduce((acc, month_needs) => acc + month_needs, 0);
  }
);
