import { useState } from 'react'
import { connect, useSelector } from 'react-redux';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';

import {
  updateNumVehicles,
  selectVehiclesPerApartment
} from '../../../features/commonDataSlice';

import CombinationLockInput from './CombinationLockInput';

const ItemNumVehicles = ({ dispatch }) => {
  const numVehicles = useSelector(state => {
    return selectVehiclesPerApartment(state)
  });
  const [isOpen, setIsOpen] = useState(false)

  const update = (value) => {
    console.log("update in itemEfficiency", value)
    dispatch(updateNumVehicles(value/10));
  };

  return (
    <ListItem sx={{ flexWrap: 'wrap' }} >
      <ListItemText
        id="switch-list-label-numVehicles"
        primary="Número de vehículos:"
        secondary="Media de vehículos por familia"
      />
      <TextField
        variant="standard"
        value={numVehicles}
        inputProps={{ style: { textAlign: 'right' }}}
        onClick={() => setIsOpen(true)}
        sx={{ width: 90, flexShrink: 0 }}
      />
      <CombinationLockInput
        num={String(parseInt(numVehicles*10, 10)).padStart(1, '0')}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        update={update}
        text="Selecciona el número de vehículos:"
      />
    </ListItem>
  );
};

export default connect()(ItemNumVehicles);
