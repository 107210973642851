import { connect } from 'react-redux';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Hidden from '@mui/material/Hidden';

import { resetSimulation } from '../app/store';
import AtelierLogo from '../resources/atelier.svg';

const MainBar = ({ dispatch }, theme) => {

  const resetSimulationType = () => {
    dispatch(resetSimulation());
  };

  return (
    <AppBar component="nav" color="paper" position="sticky">
      <Toolbar sx={{
        borderBottomStyle: "double",
        borderBottomColor: "terciary.main",
        borderBottomWidth: "thick",
        justifyContent: "space-between"
      }}>
        <Hidden smDown> 
          <Typography
            variant="h4"
            component="div"
            color="secondary"
            sx={{ cursor: "pointer" }}
            onClick={resetSimulationType}
          >
            InDESim: Integrated District Energy Simulator
          </Typography>
        </Hidden>
        <Box sx={{height: "64px"}}>
          <Button
            href="https://smartcity-atelier.eu/"
            sx={{ color: '#fff', height: "100%" }}
          >
            <img src={AtelierLogo} alt="Atelier" height="100%"/>
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
};


export default connect()(MainBar);
