import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import DTLogo from '../resources/DTLogo.png';
import BilbaoLogo from '../resources/Bilbao.png';

const BottomBar = () => {

  return (
    <AppBar
      component="nav"
      position="sticky"
      sx={{ top: 'auto', bottom: 0 }}
      color="secondary"
    >
      <Toolbar sx={{ justifyContent: "flex-end" }}>
        <Typography variant="h4" component="div" />
        <Box sx={{height: "40px"}}>
          <Button
            href="https://www.bilbao.eus/"
            sx={{ color: '#fff', height: "100%" }}
          >
            <img src={BilbaoLogo} alt="Ayuntamiento de Bilbao" height="100%" />
          </Button>
        </Box>
        <Box sx={{height: "36px"}}>
          <Button
            href="https://deustotech.deusto.es/"
            sx={{ color: '#fff', height: "100%" }}
          >
            <img
              src={DTLogo}
              alt="DeustoTech"
              height="100%"
              style={{filter: "brightness(0) invert(1)"}}
            />
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
};


export default BottomBar;
