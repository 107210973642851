import { useState } from 'react';
import { connect, useSelector } from 'react-redux';

import { useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import {
  selectCurrentChart,
  updateCurrentChart
} from '../../features/commonDataSlice';

import ChartMonthlyEnergyBalance from './ChartMonthlyEnergyBalance';
import ChartAnnualEnergyBalance from './ChartAnnualEnergyBalance';
import ChartMonthlyEnergyBalanceFull from './ChartMonthlyEnergyBalanceFull';
import ChartAnnualEnergyBalanceFull from './ChartAnnualEnergyBalanceFull';
import ChartMonthlyElectricalBalance from './ChartMonthlyElectricalBalance';
import ChartAnnualElectricalBalance from './ChartAnnualElectricalBalance';
import ChartMonthlyElectricalBalanceFull from './ChartMonthlyElectricalBalanceFull';
import ChartAnnualElectricalBalanceFull from './ChartAnnualElectricalBalanceFull';
import ChartAnnualEmissions from './ChartAnnualEmissions';
import ChartEnergeticMix from './ChartEnergeticMix';
import ChartFinancialAnalysis from './ChartFinancialAnalysis';

import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';

import ToBeImplemented from '../ToBeImplemented'

const StyledVerticalTabs = styled((props) => (
  <Tabs
    {...props}
  />
))({
  justifyContent: 'space-around',
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    width: '80%',
    backgroundColor: '#DCDC00',
  },
  '& .MuiTabs-flexContainer': {
    justifyContent: 'space-around',

  }
});

const StyledVerticalTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: 'none',
    // fontWeight: theme.typography.fontWeightRegular,
    fontWeight: 'bold',
    // fontSize: theme.typography.pxToRem(15),
    // marginRight: theme.spacing(1),
    color: '#C4C4C4',
    paddingBottom: 4,
    justifyContent: 'flex-end',
    '&.Mui-selected': {
      color: '#6D145F',
      textDecoration: 'underline',
      textDecorationColor: '#DCDC00',
      textUnderlineOffset: '5px',
      textDecorationThickness: '2px'
    },
    '&.Mui-focusVisible': {
      backgroundColor: 'rgba(100, 95, 228, 0.32)',
    },
  }),
);

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  justifyContent: 'space-around',
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    width: '80%',
    backgroundColor: '#DCDC00',
  },
  '& .MuiTabs-flexContainer': {
    justifyContent: 'space-around',

  }
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: 'none',
    // fontWeight: theme.typography.fontWeightRegular,
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(20),
    // marginRight: theme.spacing(1),
    color: '#C4C4C4',
    paddingBottom: 4,
    justifyContent: 'flex-end',
    '&.Mui-selected': {
      color: '#6D145F',
    },
    '&.Mui-focusVisible': {
      backgroundColor: 'rgba(100, 95, 228, 0.32)',
    },
  }),
);

const renderChart = (chart, period) => {
  console.log("renderChart", chart, period);
  switch(chart) {
    case 0:
      switch(period) {
        case 1: return <ChartAnnualEnergyBalance />;
        case 2: return <ChartMonthlyEnergyBalance />;
        case 4: return <ChartAnnualEnergyBalanceFull />;
        case 5: return <ChartMonthlyEnergyBalanceFull />;
        default: return <ToBeImplemented />;
      }
    case 1:
      switch(period) {
        case 1: return <ChartAnnualElectricalBalance />
        case 2: return <ChartMonthlyElectricalBalance />;
        case 4: return <ChartAnnualElectricalBalanceFull />;
        case 5: return <ChartMonthlyElectricalBalanceFull />;
        default: return <ToBeImplemented />;
      } 
    case 2:
      return <ChartEnergeticMix />;
    case 3:
      return <ChartAnnualEmissions />;
    case 4:
      return <ChartFinancialAnalysis />
    default: return <ToBeImplemented />;
  }
}

const Charts = ({ dispatch }) => {
  const [tabPeriod, setTabPeriod] = useState(2);
  const tabChart = useSelector(selectCurrentChart);
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("xl"));
  console.log("isSmallScreen: ", isSmallScreen);

  const handleChartChange = (event, newValue) => {
    console.log("renderChart", newValue, tabChart, tabPeriod);
    // setTabChart(newValue);
    dispatch(updateCurrentChart(newValue));
    if (newValue === 0 && [1, 2, 4, 5].indexOf(tabPeriod) === -1) {
       setTabPeriod(2);
    } else if (newValue === 1 && [1, 2].indexOf(tabPeriod) === -1) {
       setTabPeriod(2);
    }
  };

  const handlePeriodChange = (event, newValue) => {
    setTabPeriod(newValue);
  };

  return (
    <Stack
      direction="row"
      style={{ height: '100%', justifyContent: 'flex-start'}}
    >
      <Box
        mt={5}
        sx={{
          height: '100%',
          width: '80px',
          visibility: tabChart > 1 ? 'hidden' : 'visible',
          display: tabChart > 1 ? 'none': 'block'
        }}
      >
        <StyledVerticalTabs
          value={tabPeriod}
          onChange={handlePeriodChange}
          orientation="vertical"
          centered
        >
          <Divider>
            <Chip label="Edificios" size="small" />
          </Divider>
          <StyledVerticalTab label="Anual" />
          <StyledVerticalTab label="Mensual" />
          {[0, 1].indexOf(tabChart) !== -1 && (
            <Divider sx={{pt: 4}}>
              <Chip label="Edif+Veh" size="small" />
            </Divider>
           )}
          {[0, 1].indexOf(tabChart) !== -1 && (
            <StyledVerticalTab label="Anual" />
          )}
          {[0, 1].indexOf(tabChart) !== -1 && (
            <StyledVerticalTab label="Mensual" />
          )}
        </StyledVerticalTabs>
      </Box>
      <Stack
        direction="column"
        style={{ maxWidth: 2000, width: '100%', justifyContent: 'flex-start'}}
      >
        <Box sx={{ width: '100%', height: '48px', mb: 1 }}>
          <StyledTabs
            value={tabChart} 
            onChange={handleChartChange}
          >
            <StyledTab label={isSmallScreen ? "B. energético" : "Balance energético"} />
            <StyledTab label={isSmallScreen ? "B. eléctrico" : "Balance eléctrico"} />
            <StyledTab label={isSmallScreen ? "Mix" : "Mix consumos"} />
            <StyledTab label="Emisiones" />
            <StyledTab label={isSmallScreen ? "B. financiero" : "Balance financiero"} />
          </StyledTabs>
        </Box>
        {renderChart(tabChart, tabPeriod)}
      </Stack>
    </Stack>
  );
};

export default connect()(Charts);
